.total
{
	@include box();
	padding: 2rem 1.5rem 1rem 1.5rem !important;
	
	a:not(.button)
	// a
	{
		color: $body-font-color;
		transition: color, 0.2s;
		
		ul
		{
			list-style: none;
			margin: 0;
			font-size: rem-calc(15);
		}
	}
	a:not(.button):hover
	{
		color: $primary-color;
		i
		{
			opacity: 1;
		}
	}
}
.total-dark
{
	background: $body-font-color;
	padding: 1rem;
	margin-bottom: 1rem;
	border: 1px solid $body-font-color;
}
.total-dark *
{
	color: white;
}

legend.checkout
{
	width: 100%;
	margin-bottom: 1rem;
	// font-family: 'Roboto';
	font-size: rem-calc(18);
	// padding: 0.3rem 0 0.7rem;
	// border-top: 1px solid $medium-gray;
	// border-bottom: 1px solid $medium-gray;
	
	i
	{
		position: relative;
		top: rem-calc(6);
	}
}
label.radio
{
	@include box();
	width: 100%;
	padding: 1rem 1.5rem 0.75rem;
	color: $body-font-color;
	cursor: pointer;
}
div.radio,
{
	@include box();
	width: 100%;
	padding: 1rem 1.5rem 0.75rem;
	color: $body-font-color;
	
	label.hover
	{
		cursor: pointer;
	}
}
div.radio.dark
{
	background-color: #f2f2f2;
}
div.radio.hidden
{
	display: none;
	margin-top: 1.5rem;
}
div.hidden_fields
{
	display: none;
}
div.radio.disabled,
div.radio.disabled label.hover
{
	cursor: not-allowed;
}
.shipping-help,
.delivery-help
{
	display: none;
}
.delivery-help
{
	transition: height, 0.3s;
	margin-bottom: 0;
}