// .button.inline
// {
// 	border: rem-calc(2) solid $primary-color !important;
// 	border-radius: rem-calc(2);
// 	font-size: 1rem;
// 	line-height: 2rem;
// 	padding: 0.5rem 1.5rem;
// 	transition: border-color, 0.2s;
	
// 	i
// 	{
// 		position: relative;
// 		top: rem-calc(5);
// 		margin-right: 0.5rem;
// 	}
// }
// .button.inline:hover
// {
// 	border-color: #084849 !important;
// }

.button i
{
	position: relative;
	float: left;
}
@mixin border-thick()
{
	border: rem-calc(2) solid $primary-color !important;
	border-radius: rem-calc(2);
	font-size: 1rem;
}
.button.border-thick
{
	@include border-thick();
}
.button.inline
{
	@include border-thick();
	// margin-left: 0.5rem;
	
	@include breakpoint(medium)
	{
		margin-bottom: 0;
	}
}
.button.inline:hover
{
	border-color: darken($primary-color, 5%) !important;
}
.button.wide
{
	width: 100%;
}
.button.wide-half
{
	width: 50%;
}
.button.medium-wide-half
{
	@include breakpoint(medium)
	{
		width: 50%;
	}
}
.button.large-wide-half
{
	@include breakpoint(large)
	{
		width: 50%;
	}
}
.button.medium-narrow
{
	@include breakpoint(medium)
	{
		width: auto;
	}
}
.button.tall
{
	height: 3.5rem;
	font-family: 'Open Sans';
	font-size: 1rem;
	line-height: 1.675rem;
	
	@include breakpoint(medium)
	{
		font-size: rem-calc(19);
		line-height: 1.2rem;
	}
}
.button.bold
{
	font-family: 'Roboto Medium';
	font-size: 1rem !important;
}
.button.uppercase
{
	text-transform: uppercase;
}
.button.padded
{
	padding-left: 3rem !important;
	padding-right: 3rem !important;
}
.button.padded-small
{
	padding-left: 2rem !important;
	padding-right: 2rem !important;
}
.button.crud
{
	margin-bottom: 1rem;
}
@include breakpoint(small only)
{
	.button.crud:not(:last-of-type)
	{
		margin-right: 0.5rem;
	}
}
@include breakpoint(medium)
{
	.button.crud:not(:first-of-type) // Floated buttons will be arranged in opposite order.
	{
		margin-right: 0.5rem;
	}
}
.button.labeled
{
	line-height: rem-calc(24);
	
	i
	{
		margin-right: 0.25rem;
	}
}
.button.crud.secondary
{
	background: #fafafa;
	border: 1px solid #d9d9d9;
	color: lighten($body-font-color, 20%);
}
.button.crud.secondary:hover
{
	background: darken(#fafafa, 5%);
	color: lighten($body-font-color, 10%);
}
.button.crud.hidden
{
	// transition: opacity, 0.1s;
	opacity: 0;
	visibility: hidden;
	// float: none;
	position: absolute;
}
.button.crud.move
{
	margin: 0 0 0 1rem;
}
input.button-prefix
{
	
}
input.button-prefix:hover + i
{
	background: darken($primary-color, 5%);
	border-color: darken($primary-color, 5%);
}
// div.bottom
// {
// 	position: relative;
// 	background: orange;
// 	// height: 15rem;
// }
// .button.bottom
// {
// 	position: absolute;
// 	bottom: 0;
// 	margin-bottom: 0;
// }

td.tracking_link .button
{
	width: auto;
	margin: 0rem;
	padding: 0.5rem;
	
	i
	{
		// font-size: 1rem;
	}
}