.row.category
{
	// margin-bottom: 2rem;
	
	.column.category
	{
		.outer
		{
			@include breakpoint(medium)
			{
				min-height: 32rem;
			}
			
			.top
			{
				transition: background, 0.3s;
				
				img
				{
					// min-width: rem-calc(373);
					// min-height: rem-calc(280);
				}
			}
			
			.bottom
			{
				padding: 0.5rem 1.5rem;
				
				@include breakpoint(medium)
				{
					padding: 1rem 2rem;
				}
				
				h2
				{
					margin: 0;
					
					font-size: rem-calc(18);
					
					// font-family: 'Open Sans Bold';
					// font-size: rem-calc(15);
					// color: $body-font-color;
					// text-transform: uppercase;
				}
				
				p
				{
					color: $body-font-color;
					line-height: rem-calc(30);
				}
			}
		}
		.outer:hover
		{
			background: darken(white, 3%);
			
			.top
			{
				// background: darken(#eeeeee, 3%);
			}
		}
	}
}

p.product-description
{
	font-size: 1.2rem;
	margin-bottom: 2rem;
}

.product
{
	@include box();
	margin-bottom: 2rem;
	
	.product-image
	{
		background: #e7e7e7;
	}
	
	div.product-description
	{
		padding: 5rem 4rem 3rem 4rem;
		color: $body-font-color;
		
		h3
		{
			margin-bottom: 1rem;
		}
	}
}
a:hover .product
{
	background: darken(white, 3%);
}