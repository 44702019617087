/*
-------------------------------------------------------------------------------------
//	TYPOGRAPHY
-------------------------------------------------------------------------------------
*/

/*
 *	FONT IMPORTS
 */
@font-face
{
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face
{
	font-family: 'Open Sans Semibold';
	font-style: normal;
	font-weight: 600;
	src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

// @font-face
// {
// 	font-family: 'Open Sans Bold';
// 	font-style: normal;
// 	font-weight: 700;
// 	src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
// 	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
// }

@font-face
{
	font-family: 'Open Sans Bold Italic';
	font-style: italic;
	font-weight: 700;
	src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxolIZu-HDpmDIZMigmsroc4.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face
{
	font-family: 'Roboto Light';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face
{
	font-family: 'Roboto Light Italic';
	font-style: italic;
	font-weight: 300;
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(https://fonts.gstatic.com/s/roboto/v15/7m8l7TlFO-S3VkhHuR0at44P5ICox8Kq3LLUNMylGO4.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face
{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v15/CWB0XYA8bzo0kSThX0UTuA.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face
{
	font-family: 'Roboto Italic';
	font-style: italic;
	font-weight: 400;
	src: local('Roboto Italic'), local('Roboto-Italic'), url(https://fonts.gstatic.com/s/roboto/v15/vPcynSL0qHq_6dX7lKVByfesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face
{
	font-family: 'Roboto Medium';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v15/RxZJdnzeo3R5zSexge8UUVtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face
{
	font-family: 'Roboto Bold';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face
{
	font-family: 'Roboto Black';
	font-style: normal;
	font-weight: 900;
	src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v15/mnpfi9pxYH-Go5UiibESIltXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face
{
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/materialicons/v18/2fcrYFNaTjcS6g4U3t-Y5SQZRqoBW5Q4K_a7BOZJO20.eot); /* For IE6-8 */
	src: local('Material Icons'),
	   	 local('MaterialIcons-Regular'),
	     url(https://fonts.gstatic.com/s/materialicons/v18/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format('woff2'),
	     url(https://fonts.gstatic.com/s/materialicons/v18/2fcrYFNaTjcS6g4U3t-Y5ewrjPiaoEww8AihgqWRJAo.woff) format('woff'),
	     url(https://github.com/google/material-design-icons/blob/master/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face
{
	font-family: 'Material-Design-Iconic-Font';
	src: url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.woff2?v=2.2.0) format('woff2'),
		 url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.woff?v=2.2.0) format('woff'),
		 url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.ttf?v=2.2.0) format('truetype')
}

/*
 *	MATERIAL DESIGN ICONS
 */
@mixin material()
{
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: rem-calc(24);  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	/* Support for IE. */
	font-feature-settings: 'liga';
}
.material-icons
{
	@include material();
}

/*
 *	LOGO
 */
svg#logo .logo_text
{
	font-family: 'Open Sans Bold Italic';
	font-size: rem-calc(12);
	fill: #0f9092;
	text-transform: uppercase;
}

/*
 *	HEADING SIZES
 */

$header-sizes:
(
	small:
	(
		'h1': 40,
		'h2': 36,
		'h3': 24,
		'h4': 20,
		'h5': 19,
		'h6': 16,
	),
	medium:
	(
	    'h1': 48,
	    'h2': 40,
	    'h3': 31,
	    'h4': 25,
	    'h5': 20,
	    'h6': 16,
	)
);

.h1
{
	font-size: rem-calc(40);
	
	@include breakpoint(large)
	{
		font-size: rem-calc(48);
	}
}
.h2
{
	font-size: rem-calc(36);
	// font-size: rem-calc(80);
	
	@include breakpoint(large)
	{
		font-size: rem-calc(40);
	}
}
.h3
{
	font-size: rem-calc(24);
	
	@include breakpoint(large)
	{
		font-size: rem-calc(31);
	}
}
.h4
{
	font-size: rem-calc(20);
	
	@include breakpoint(large)
	{
		font-size: rem-calc(25);
	}
}
.h5
{
	font-size: rem-calc(19);
	
	@include breakpoint(large)
	{
		font-size: rem-calc(20);
	}
}
.h6
{
	font-size: 1rem;
}
.h7
{
	font-size: 0.9rem;
}

/*
 *	HEADING FONT
 */
$header-font-family: 'Roboto Light';

/*
 *	BODY FONT
 */
$body-font-family: 'Roboto Light';
$body-font-color: #4c4c4c;

/*
 *	NAVBAR FONT
 */
.top-bar
{
	font-family: 'Roboto Light';
	// font-size: rem-calc(18);
}

/*
 *	ANCHORS
 */
a.underlined
{
	text-decoration: underline;
}

/*
 *	HEADING STYLES
 */
.highlight
{
	font-family: 'Roboto Medium';
	color: #4c4c4c;
}
.highlight-colourless
{
	font-family: 'Roboto Medium';
}
.padding
{
	padding: 1rem;
	line-height: 2rem;
}
h1.background,
h2.background,
h3.background,
h4.background,
h5.background,
h6.background
{
	width: 100%;
	height: 4rem;
	margin-bottom: 0;
	// background: darken(white, 5%);
	background: #e6ebeb;
	transition: background, 0.3s;
}
h1.margin-top,
h2.margin-top,
h3.margin-top,
h4.margin-top,
h5.margin-top,
h6.margin-top,
p.margin-top
{
	margin-top: 1rem;
}
h1.margin-top-large,
h2.margin-top-large,
h3.margin-top-large,
h4.margin-top-large,
h5.margin-top-large,
h6.margin-top-large,
p.margin-top-large
{
	margin-top: 2rem;
}
h1.margin-bottom,
h2.margin-bottom,
h3.margin-bottom,
h4.margin-bottom,
h5.margin-bottom,
h6.margin-bottom,
p.margin-bottom
{
	margin-bottom: 2rem;
}
h1.margin-top-small,
h2.margin-top-small,
h3.margin-top-small,
h4.margin-top-small,
h5.margin-top-small,
h6.margin-top-small,
p.margin-top-small
{
	margin-top: 0.25rem;
}
span.margin-top-half
{
	margin-top: 0.5rem;
}
h1.margin-bottom-small,
h2.margin-bottom-small,
h3.margin-bottom-small,
h4.margin-bottom-small,
h5.margin-bottom-small,
h6.margin-bottom-small,
p.margin-bottom-small
{
	margin-bottom: 1rem;
}
h1.no-margin-bottom,
h2.no-margin-bottom,
h3.no-margin-bottom,
h4.no-margin-bottom,
h5.no-margin-bottom,
h6.no-margin-bottom,
p.no-margin-bottom
{
	margin-bottom: 0;
}
p.help-bigger
{
	font-size: rem-calc(14);
}
h3.price
{
	width: 100%;
	text-align: right;
	margin-bottom: 0;
	
	@include breakpoint(small only)
	{
		font-size: rem-calc(31);
	}
}
h3.price-dark
{
	line-height: 1.75rem;
	margin-bottom: 0;
	
	@include breakpoint(small only)
	{
		text-align: left;
	}
}
p.subtitle
{
	width: 100%;
	text-align: right;
}
p.hidden
{
	display: none;
}
.h6.italic
{
	font-family: 'Roboto Light Italic';
}

/*
 *	CRUD HEADINGS
 */
h1.inline,
h2.inline,
h3.inline,
h4.inline,
h5.inline,
h6.inline
{
	// float: left;
	display: inline-block;
	margin-right: 1rem;
}

/*
 *	HUMAN READABLE STRINGS
 */
span.faux_gap
{
	display: inline-block;
	width: 0.2em;
}

section#hero
{
	color: white;
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p
	{
		text-transform: uppercase;
	}
	
	h1.h5
	{
		font-family: 'Roboto Bold';
		font-size: rem-calc(26);
		color: #5ee0c9;
		margin-bottom: 1rem;
	}
	
	h2
	{
		font-size: rem-calc(46);
		line-height: rem-calc(42);
		
		strong
		{
			font-family: 'Roboto Black';
		}
	}
	
	h3.h6
	{
		font-family: 'Roboto Light';
		text-transform: none;
		font-size: rem-calc(18);
		line-height: rem-calc(30);
		margin-top: 1rem;
		
		@include breakpoint(medium)
		{
			font-family: 'Roboto';
			// text-transform: uppercase;
		}
	}
	
	.shipping
	{
		@include breakpoint(medium)
		{
			text-align: right;
		}
		
		h3.h4
		{
			margin: 0;
			line-height: rem-calc(30);
			
			strong
			{
				display: block;
				font-family: 'Roboto Black';
			}
		}
	}
}

/*
 *	ORDERS SECTION
 */
section#orders
{
	p.description
	{
		line-height: rem-calc(30);
	}
	p.blurb
	{
		font-size: rem-calc(15);
	}
	
	h5.h6
	{
		font-family: 'Roboto Medium';
		font-size: rem-calc(18);
	}
}

p.small
{
	font-size: 0.75rem;
}

/*
 *	PRODUCTS SECTION
 */
p.associates
{
	font-family: 'Roboto';
	// font-size: rem-calc(17);
}