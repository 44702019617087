/*
//	Navigation
-----------------------------------
*/

$title-bar-menu-background: white;
$title-bar-menu-secondary-background: darken($title-bar-menu-background, 2%);

#main-nav
{
	// Draw menu-items above breadcrumbs.
	position: relative;
	z-index: 3;
	
	@include breakpoint(small only)
	{
		// Account for drop-shadow extent.
		margin-top: rem-calc(-8);
		// Account for title-bar cutout.
		margin-top: -2rem;
		// padding-top: 1.5rem;
		
		background: $title-bar-menu-background;
		
		.top-bar-right
		{
			// Give the title some more room when the menu is expanded.
			margin-bottom: 1rem;
		}
		
		// Add padding to the top list element to account for the title-bar curve.
		ul#top li:first-of-type
		{
			padding-top: 1.5rem;
		}
	}
}
#main-nav-shadow
{
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	width: 100%;
	height: rem-calc(64);
	// padding-right: 100rem;
	// margin-right: -100rem;
	background: white;
	@include shadow(0.1);
}
#main-nav-background
{
	position: absolute;
	z-index: -2;
	left: 0;
	top: 0;
	width: 100%;
	height: rem-calc(88);
}
// Darken the main nav menus when the titlebar is visible.
@include breakpoint(small only)
{
	#main-nav ul.menu
	{
		background: $title-bar-menu-background;
		border-top: 0;
		border-right: 0;
		border-left: 0;
	}
	#main-nav ul.menu li:hover
	{
		background: darken($title-bar-menu-background, 2%);
		// background: $title-bar-menu-background;
	}
	
	#main-nav ul.menu ul.menu
	{
		// background: darken($title-bar-menu-background, 4%);
		background: $title-bar-menu-secondary-background;
		// background: #eef5f1;
	}
	#main-nav ul.menu ul.menu li:hover
	{
		background: darken($title-bar-menu-secondary-background, 2%);
	}
}

// Put the menu back in the navbar, on top of the shadow div.
#main-nav-shadow + ul.menu
{
	margin-top: rem-calc(-64);
}

.top-bar
{
	height: rem-calc(64);
	
	.main-logo
	{
		float: left;
		clear: none;
		padding: 1rem 4rem rem-calc(21) 100rem; // Bottom padding accounts for the drop-shadow.
		// padding: 1rem 4rem rem-calc(13) 100rem;
		margin-left: -100rem;
		
		background-image: url('../img/nav-curve.svg');
		background-position: top right;
		background-repeat: repeat-x;
		overflow: visible;
	}
	
	li.menu-text a
	{
		padding: 0;
	}
}

.title-bar
{
	height: rem-calc(64);
	padding: 0;
	background: white;
	color: $primary-color;
	
	.main-logo
	{
		float: left;
		clear: none;
		padding: 1rem 4rem rem-calc(21) 10rem; // Bottom padding accounts for the drop-shadow.
		margin-left: -10rem;
		
		background-image: url('../img/nav-curve.svg');
		background-position: top right;
		background-repeat: repeat-x;
		overflow: visible;
		
		position: relative;
		z-index: 5;
	}
	
	.title-bar-right
	{
		// Avoid the menu button being covered by the title-bar shadow.
		position: relative;
		z-index: 6;
		
		#title-bar-button
		{
			float: right;
			clear: none;
			width: 8rem;
			padding: 1.25rem 1rem;
			border-left: $menu-border;
			
			i
			{
				margin-left: 0.5rem;
				// font-size: rem-calc(29);
			}
		}
	}
}
#title-bar-shadow
{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: rem-calc(64);
	// padding-right: 100rem;
	// margin-right: -100rem;
	@include shadow(0.1);
	z-index: 4;
}