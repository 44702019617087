@mixin unstyled()
{
	list-style: none;
}

ul.unstyled
{
	@include unstyled();
}

@mixin margin-x-none()
{
	margin-left: 0;
	margin-right: 0;
}

ul.margin-x-none
{
	@include margin-x-none();
}

ul.margin-none
{
	margin: 0;
}

// .user-addresses ul.address
ul.address
{
	@include unstyled();
	@include margin-x-none();
	
	font-family: 'Roboto Light';
	font-size: rem-calc(15);
	color: $body-font-color;
	line-height: 1.5rem;
}

ul.position
{
	display: table;
	width: 100%;
	margin-left: 0;
	list-style: none;
	
	.test
	{
		// display: table-row;
	}
	
	.category
	{
		// display: table-cell;
		// float: left;
	}
	
	.buttons
	{
		// display: table-cell;
		float: right;
		min-width: 7.85rem;
		background: orange;
	}
	
	li
	{
		@include box();
		
		padding: rem-calc(12) 1rem;
		margin-bottom: 1rem;
	}
}

ul.short
{
	li a
	{
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}
}

ul.margin-top,
li.margin-top
{
	margin-top: 1rem;
}
ul.margin-top-half,
li.margin-top-half
{
	margin: 0.5rem 0 0;
}

ul.padded
{
	padding: 1.5rem !important;
}
ul.padded-taller
{
	padding: 1.75rem 1.5rem !important;
}

ul.phone
{
	font-size: 1.1rem;
}
ul.phone li.icon::before
{
	top: rem-calc(4);
}

ul.inline
{
	li
	{
		display: inline-block;
	}
	li:not(:first-of-type)
	{
		margin-left: 1rem;
	}
	li::before
	{
		color: $primary-color;
	}
}

ul.totals
{
	font-size: 1rem;
	line-height: 2rem;
	margin: 0;
	
	li.large
	{
		font-family: 'Roboto Light';
		font-size: 1.75rem;
	}
}

/*
//	Terms and Conditions
-----------------------------------
*/

#term-links
{
	display: none;
	// min-width: rem-calc(270);
	
	li a.active
	{
		color: white;
		background: $primary-color;
	}
}

#terms-sections
{
	ol#clauses
	{
		li.clause
		{
			padding-top: 1.5rem;
			margin-bottom: 1px;
		}
	}
}