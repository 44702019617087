.flashdata
{
	padding-top: 1rem;
	// min-height: rem-calc(66);
	
	@include breakpoint(large)
	{
		padding-bottom: 1rem;
	}
	
	.callout
	{
		padding: 0.75rem 1rem;
		margin-bottom: 0;
	}
}
.flashdata.persistent
{
	padding-top: 1rem;
	// min-height: rem-calc(82);
	// background: orange;
	
	@include breakpoint(large)
	{
		padding-top: 3.25rem;
		min-height: rem-calc(122);
	}
}

.notstatic
{
	position: relative;
}

.floating
{
	position: absolute;
	width: 100%;
	margin-top: 1rem;
	min-height: rem-calc(74);
	// float: left;
	background: orange;
}