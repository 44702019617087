#hours
{
	padding: 2rem 1.5rem;
	color: white;
}
.open
{
	background: $primary-color;
}
.closed
{
	background: #ff8e50;
}
a.contact_number.disabled
{
	pointer-events: none;
	color: $body-font-color;
}

#map
{
	width: 100%;
	height: 9rem;
	background-image: url('../img/map.jpg');
	background-position: center;
}