section#hero
{
	width: 100%;
	min-height: rem-calc(450);
	margin: 0;
	
	// @include breakpoint(large)
	// {
		margin: -2rem 0 0;
	// }
	
	.headings
	{
		margin-top: 1rem;
		
		@include breakpoint(medium)
		{
			margin-top: 3rem;
		}
		@include breakpoint(large)
		{
			margin-top: 1.75rem;
		}
	}
	
	.shipping
	{
		margin-bottom: 2rem;
		
		.background
		{
			width: auto;
			max-width: 21rem;
			padding: 1.25rem 1.5rem 1rem 1.5rem;
			background: rgba(0,0,0,0.3);
			
			@include breakpoint(large)
			{
				float: right;
			}
			
			h3.h4,
			p
			{
				@include breakpoint(medium)
				{
					float: right;
					clear: none;
				}
			}
			
			p
			{
				font-size: rem-calc(13.75);
				margin-bottom: 0;
			}
			
			.new_zealand
			{
				// margin-top: 0.5rem;
				margin-right: 0.75rem;
				float: left;
				clear: none;
				
				@include breakpoint(medium)
				{
					float: right;
					margin-right: 0;
					margin-left: 0.75rem;
				}
			}
		}
		// Clearfix for float container.
		.background::after
		{
			content: "";
			display: block;
			clear: both;
		}
	}
}

section#orders
{
	// margin-top: 2rem;
	
	.row.features
	{
		margin-top: 1rem;
	}
}

section#quality
{
	// margin-top: 2rem;
	// padding-top: 2rem;
	// padding-bottom: 2rem;
	
	.highlight p
	{
		font-family: 'Roboto Light Italic';
		font-size: 1.5rem;
		color: $primary-color;
	}
	
	p
	{
		// font-family: 'Roboto';
		line-height: 2rem;
	}
}

section#associates
{
	background: -moz-linear-gradient(top, rgba(0,0,0,0.06) 0%, rgba(0,0,0,0.04) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0.06) 0%,rgba(0,0,0,0.04) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0.06) 0%,rgba(0,0,0,0.04) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f000000', endColorstr='#0a000000',GradientType=0 );
	
	#logos_associates
	{
		position: relative;
		
		img
		{
			padding: 0 0.5rem;
		}
	}
}

section#contact
{
	// padding: 2rem 0;
}