$thumbnail-border: 1px solid #dddddd;
$thumbnail-shadow: 0;

ul.thumbnails.image_picker_selector li
{
	// width: rem-calc(280);
	min-width: rem-calc(200);
	width: 24.375%;
	margin-bottom: rem-calc(12);
}
ul.thumbnails.image_picker_selector li:not(:last-of-type)
{
	// margin-right: 0.5%;
}

ul.thumbnails.image_picker_selector li .thumbnail
{
	padding: rem-calc(4);
	margin-bottom: 0 !important;
}

ul.thumbnails.image_picker_selector li .thumbnail.selected
{
	background: $primary-color;
	border-color: $primary-color;
}
.image_picker_image:hover
{
	cursor: pointer;
}