$topbar-padding: 0;
$menu-item-padding: 1.5rem 2rem;
$menu-border: 1px solid #e6eded;
$menu-background: white;

ul.menu
{
	background: $menu-background;
}
.menu li
{
	transition: background-color, 0.3s;
}

// Account for anchors with multiple lines of text.
.menu li.is-submenu-item a
{
	line-height: 1.5rem;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.top-bar-left ul
{
	// height: rem-calc(64);
}

// Account for menu item borders in sub-menus opened from the right.
.menu.dropdown li.opens-right .menu.vertical
{
	left: -1px !important;
}

// Separate main navbar links.
.top-bar-left .menu.dropdown > li,
.top-bar-right .menu.dropdown > li:last-of-type
{
	border-right: $menu-border;
}
.top-bar-right .menu.dropdown > li,
.top-bar-left .menu.dropdown > li:nth-of-type(2)
{
	border-left: $menu-border;
}

// Remove borders applied to vertical menus for medium and above screens.
.menu.vertical.medium-horizontal
{
	@include breakpoint(medium)
	{
		border: none;
		
		> li:not(:last-of-type)
		{
			border-bottom: none;
		}
	}
}

.menu li:hover
{
	background: darken($menu-background, 2%);
}

.menu.vertical
{
	border: $menu-border;
	
	// Separate dropdown menu links.
	li:not(:last-of-type)
	{
		border-bottom: $menu-border;
	}
	
	// Style form submit buttons similarly to menu anchors.
	li form button
	{
		padding: $menu-item-padding;
		color: $primary-color;
	}
}

// Widen dropdown menus and reposition psuedo-element arrows.
.menu.dropdown li.is-dropdown-submenu-parent a
{
	padding-right: 3rem !important;
}
.menu.dropdown li.is-dropdown-submenu-parent a::after
{
	right: 2rem !important;
}

/*
//	Curve
-----------------------------------
*/

// .curve
// {
// 	width: 20%;
// 	height: 2rem;
// 	background: orange;
// 	opacity: 0.5;
// }

/*
//	Prevent a FoUC on the responsive navigation
-----------------------------------
*/

.no-js
{
	@include breakpoint(small only)
	{
		.top-bar
		{
			display: none;
		}
	}

	@include breakpoint(medium)
	{
		.title-bar
		{
			display: none;
		}
	}
}