i.hover
{
	color: $primary-color;
	opacity: 0;
	transition: opacity, 0.2s;
}
i.button-prefix
{
	padding: 0.85rem 0 0.85rem 2rem;
	cursor: default;
	color: white;
	line-height: 1rem;
	background: $primary-color;
	border: 1px solid $primary-color;
	transition: background, 0.3s;
}
i.button-prefix:hover
{
	background: darken($primary-color, 5%);
}
.pointer
{
	cursor: pointer;
}

i.left
{
	margin-right: 0.5rem;
}
i.right
{
	margin-left: 0.5rem;
}
i.top
{
	position: relative;
	top: rem-calc(5);
}

i.alert
{
	color: $alert-color;
}

i.navbar
{
	line-height: 0;
}

i.status
{
	margin-left: 0.25rem;
	line-height: 1rem;
	font-size: rem-calc(22);
	color: #ff8e65;
}

.user-details
{
	li.name::before
	{
		content: "account_circle";
	}
	li.company::before
	{
		content: "work";
	}
	li.email::before
	{
		content: "mail";
	}
	li.email_secondary::before
	{
		content: "mail_outline";
	}
	li.phone::before
	{
		content: "phone";
	}
	li.date::before
	{
		content: "event";
	}
	li.password::before
	{
		content: "vpn_key";
	}
	li.bank_name::before
	{
		content: "account_balance";
	}
	li.bank_branch::before
	{
		content: "place";
	}
	li.bank_account_name::before
	{
		content: "account_box";
	}
	li.bank_account_number::before
	{
		content: "monetization_on";
	}
	li.gst::before
	{
		content: "business_center";
	}
}
.user-addresses i
{
	color: $primary-color;
}
.user-addresses h2.background:hover i
{
	color: white;
}

/*
 * List item icons.
 */
li.icon::before
{
	@include material();
	
	position: relative;
	top: rem-calc(5);
	margin-right: 1rem;
	font-size: rem-calc(21);
}
li.icon.margin-half::before
{
	margin-right: 0.5rem;
}
li.merge:not(:first-of-type)::before
{
	visibility: hidden;
}
li.icon.phone_landline::before
{
	content: "phone";
}
li.icon.phone_mobile::before
{
	content: "smartphone";
}
li.icon.eaddress::before
{
	content: "mail_outline";
}
li.icon.clock::before
{
	content: "schedule";
}
li.icon.pin::before
{
	content: "place";
}
li.icon.tick::before
{
	content: "check";
}
li.icon.cross::before
{
	content: "clear";
}

.feature
{
	width: 100%;
	text-align: center;
	margin-bottom: 1rem;
	
	.border
	{
		width: 9.5rem;
	    height: 9.5rem;
	    border-radius: 4.75rem;
	    line-height: 9.5rem;
		padding-top: 0.25rem;
	    
		width: calc(9rem + 2px);
	    height: calc(9rem + 2px);
	    border-radius: calc(4.5rem + 2px);
	    line-height: calc(9rem + 2px);
		padding-top: calc(0.5px + 0.5px);
	    
	    margin: auto;
		background: #dddddd;
		
		i
		{
			width: 9rem;
		    height: 9rem;
		    border-radius: 4.5rem;
		    line-height: 9rem;
		    text-align: center;
		    
			font-size: 5.5rem;
			color: #6b6b6b;
		    background: white;
		    // transition: background, 0.3s;
		}
	}
}
.feature *:hover
{
	cursor: default;
}
.feature i:hover
{
	// background: darken(white, 3%);
}