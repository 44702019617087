.menu.vertical.profile
{
	font-family: 'Roboto Medium';
}

.user-details
{
	margin-bottom: 1rem;
	@include box();
	
	a
	{
		display: block;
	}
	
	ul:not(.address)
	{
		list-style: none;
		// list-style-image: url('../face.png');
		color: $body-font-color;
		padding-bottom: 1rem;
		margin-bottom: 0;
		
		li
		{
			height: 2.875rem;
		}
	}
	ul li::before
	{
		@include material();
		
		position: relative;
		top: rem-calc(5);
		margin-right: 1rem;
		font-size: rem-calc(21);
		color: lighten($body-font-color, 25%);
	}
	
	.row
	{
		padding: 1rem;
	}
}
.user-details:not(.no-hover):hover
{
	background: darken(white, 3%);
	
	h2
	{
		// background: darken(#f7f7f7, 3%);
	}
	
	i
	{
		opacity: 1;
	}
	
	// h1.background,
	// h2.background,
	// h3.background,
	// h4.background,
	// h5.background,
	// h6.background
	// {
	// 	background: darken(white, 5%);
	// }
}

.user-addresses
{
	background: white;
	border: 1px solid #cecece;
	
	padding: 0;
	
	h1.background,
	h2.background,
	h3.background,
	h4.background,
	h5.background,
	h6.background
	{
		a
		{
			display: block;
			padding: 1rem;
			line-height: 2rem;
			color: $body-font-color;
			transition: background, 0.2s;
		}
		a:hover
		{
			color: white;
			background: $primary-color;
		}
	}
	
	a.hover-box
	{
		display: block;
		padding: 1rem 2rem;
		
		h2
		{
			line-height: 2rem;
		}
		
		
	}
	a.hover-box:hover
	{
		// border-bottom: 1px solid #cecece;
		background: darken(white, 3%);
		transition: background-color, 0.3s;
		
		i
		{
			opacity: 1;
		}
	}
	
	hr
	{
		width: 90%;
		margin: 0 auto;
		transition: width, 0.2s;
	}
	hr:last-of-type
	{
		display: none;
	}
}
.user-addresses:hover
{
	hr
	{
		width: 100%;
	}
}