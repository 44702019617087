/*
-------------------------------------------------------------------------------------
//	UTILITY
-------------------------------------------------------------------------------------
*/

/*
//	Anchors
-----------------------------------
*/

a
{
	outline: none;
}


/*
//	Shadows
-----------------------------------
*/

@mixin shadow($opacity)
{
	box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, $opacity);
}


/*
//	Columns
-----------------------------------
*/

.column.collapse
{
    padding-left: 0;
    padding-right: 0;
}

.column.padded
{
	@include breakpoint(large)
	{
		padding: 0 8rem;
	}
	
	padding: 0 3rem;
	
	@include breakpoint(small down)
	{
		padding: 0 1rem;
	}
}


/*
//	Margins
-----------------------------------
*/

.margin-bottom-none
{
	margin-bottom: 0 !important;
}
.margin-bottom-half
{
	margin-bottom: 0.5rem !important;
}
.margin-bottom
{
	margin-bottom: 1rem !important;
}
.margin-bottom-double
{
	margin-bottom: 2rem !important;
}
.margin-bottom-quadruple
{
	margin-bottom: 4rem !important;
}

.margin-top-none
{
	margin-top: 0 !important;
}
.margin-top-half
{
	margin-top: 0.5rem !important;
}
.margin-top
{
	margin-top: 1rem !important;
}
.margin-top-double
{
	margin-top: 2rem !important;
}
.margin-top-quadruple
{
	margin-top: 4rem !important;
}

.margin-top-small
{
	@include breakpoint(small down)
	{
		margin-top: 1rem !important;
	}
}
.margin-top-medium
{
	@include breakpoint(medium down)
	{
		margin-top: 1rem !important;
	}
}

/*
//	Padding
-----------------------------------
*/

.padding-top-half
{
	padding-top: 0.5rem;
}
.padding-top
{
	padding-top: 1rem;
}
.padding-top-double
{
	padding-top: 2rem;
}
.padding-top-quadruple
{
	padding-top: 4rem;
}

.padding-bottom-half
{
	padding-bottom: 0.5rem;
}
.padding-bottom
{
	padding-bottom: 1rem;
}
.padding-bottom-double
{
	padding-bottom: 2rem;
}
.padding-bottom-quadruple
{
	padding-bottom: 4rem;
}


/*
//	Sections
-----------------------------------
*/

section.white
{
	background: white;
	
	@include shadow(0.06);
}

section.padded
{
	padding: 1rem 0;
	
	@include breakpoint(medium)
	{
		padding: 2rem 0;
	}
}

section.delineated
{
	border-top: 1px solid #cecece;
	border-bottom: 1px solid #cecece;
}
section.delineated-top
{
	border-top: 1px solid #cecece;
}
section.delineated-bottom
{
	border-bottom: 1px solid #cecece;
}

section.shaded
{
	@include shadow(0.06);
}


/*
//	Divs
-----------------------------------
*/

@mixin box()
{
	@include box_hollow();
	
	background: white;
}
@mixin box_hollow()
{
	border: 1px solid #cecece;
	transition: background-color, 0.3s;
}
@mixin box-dark()
{
	background: darken(white, 4%);
	border: 1px solid #cecece;
	transition: background-color, 0.3s;
}

.box
{
	@include box();
}
.box.padded
{
	padding: 2rem 1.5rem 1rem 1.5rem !important;
}
.box_hollow
{
	@include box_hollow();
}
.box_attached
{
	@include box();
	
	border-top: 0;
	padding: 1rem 2rem;
}
.box.hover:hover
{
	background: darken(white, 3%);
}

.border-right-medium
{
	@include breakpoint(medium)
	{
		border-right: 1px solid #cdcdcd;
	}
}


/*
//	Images
-----------------------------------
*/

// .border
// {
// 	border: 1px solid #cdcdcd;
// 	// transition: background, 0.2s;
// }
// .border:hover
// {
// 	background: white;
// }


/*
//	Floats
-----------------------------------
*/

.float-right-medium
{
	@include breakpoint(medium up)
	{
		float: right;
	}
}
.float-right-large
{
	@include breakpoint(large up)
	{
		float: right;
	}
}