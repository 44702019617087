$input-border: 1px solid #cecece;
$input-background: white;
$input-font-family: 'Roboto';
$input-color: #595959;

.input-group-label.spaced
{
	min-width: 4rem;
}

.required
{
	color: red;
}

input[type="text"],
input[type="number"],
input[type="password"]
{
	padding-right: 1rem;
}
input[type="text"],
input[type="number"],
input[type="password"],
select
{
	height: 3rem;
	padding-left: 1rem;
	font-family: 'Roboto';
	color: #595959;
}
select option
{
	background: #fcfcfc;
	padding: 0.75rem 1rem;
}
input.tall,
select.tall
{
	height: 3.5rem;
}
option.blank
{
	color: lighten($body-font-color, 35%);
}
label.hover
{
	cursor: pointer;
}

/*
 * Logout form.
 */
form#logout button:hover
{
	cursor: pointer;
}

/*
 * Input placeholders.
 */
input::-webkit-input-placeholder // Chrome;
{
	color: #c7c7c7 !important;
}
input:-moz-placeholder // Firefox 4 to 18;
{
	color: #c7c7c7 !important;
	opacity: 1;
}
input::-moz-placeholder // Firefox 19 +;
{
	color: #c7c7c7 !important;
	opacity: 1;
}
input:-ms-input-placeholder // IE 10 +;
{
	color: #c7c7c7 !important;
}

#product_create input[type="checkbox"] + label,
#product_edit input[type="checkbox"] + label
{
	margin-left: 0;
	margin-right: 1.5rem;
	padding-left: 0.25rem;
	padding-right: 0;
}

/*
 * Quantity inputs.
 */
input[type="number"].quantity
{
	width: 4rem;
	
	@include breakpoint(medium)
	{
		width: 6rem;
	}
}
input[type="number"].stock
{
	width: 8rem;
	margin-left: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

/*
 * Fieldsets.
 */
.fieldset.mobile
{
	margin-top: rem-calc(2);
	padding-bottom: rem-calc(29);
	
	legend
	{
		font-size: rem-calc(14);
		color: $form-label-color;
		background: transparent;
	}
}

.box .fieldset
{
	margin-top: 0;
	
	legend
	{
		background: transparent;
	}
}

/*
 * Error classes.
 */
.form-error
{
	// margin-top: 0;
}