@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*
-------------------------------------------------------------------------------------
//	TYPOGRAPHY
-------------------------------------------------------------------------------------
*/
/*
 *	FONT IMPORTS
 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Open Sans Bold Italic';
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url(https://fonts.gstatic.com/s/opensans/v13/PRmiXeptR36kaC0GEAetxolIZu-HDpmDIZMigmsroc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215; }

@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Light Italic';
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url(https://fonts.gstatic.com/s/roboto/v15/7m8l7TlFO-S3VkhHuR0at44P5ICox8Kq3LLUNMylGO4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215; }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v15/CWB0XYA8bzo0kSThX0UTuA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215; }

@font-face {
  font-family: 'Roboto Italic';
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url(https://fonts.gstatic.com/s/roboto/v15/vPcynSL0qHq_6dX7lKVByfesZW2xOQ-xsNqO47m55DA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215; }

@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v15/RxZJdnzeo3R5zSexge8UUVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v15/mnpfi9pxYH-Go5UiibESIltXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v18/2fcrYFNaTjcS6g4U3t-Y5SQZRqoBW5Q4K_a7BOZJO20.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(https://fonts.gstatic.com/s/materialicons/v18/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format("woff2"), url(https://fonts.gstatic.com/s/materialicons/v18/2fcrYFNaTjcS6g4U3t-Y5ewrjPiaoEww8AihgqWRJAo.woff) format("woff"), url(https://github.com/google/material-design-icons/blob/master/iconfont/MaterialIcons-Regular.ttf) format("truetype"); }

@font-face {
  font-family: 'Material-Design-Iconic-Font';
  src: url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.woff2?v=2.2.0) format("woff2"), url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.woff?v=2.2.0) format("woff"), url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.ttf?v=2.2.0) format("truetype"); }

/*
 *	MATERIAL DESIGN ICONS
 */
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

/*
 *	LOGO
 */
svg#logo .logo_text {
  font-family: 'Open Sans Bold Italic';
  font-size: 0.75rem;
  fill: #0f9092;
  text-transform: uppercase; }

/*
 *	HEADING SIZES
 */
.h1 {
  font-size: 2.5rem; }
  @media print, screen and (min-width: 64em) {
    .h1 {
      font-size: 3rem; } }

.h2 {
  font-size: 2.25rem; }
  @media print, screen and (min-width: 64em) {
    .h2 {
      font-size: 2.5rem; } }

.h3 {
  font-size: 1.5rem; }
  @media print, screen and (min-width: 64em) {
    .h3 {
      font-size: 1.9375rem; } }

.h4 {
  font-size: 1.25rem; }
  @media print, screen and (min-width: 64em) {
    .h4 {
      font-size: 1.5625rem; } }

.h5 {
  font-size: 1.1875rem; }
  @media print, screen and (min-width: 64em) {
    .h5 {
      font-size: 1.25rem; } }

.h6 {
  font-size: 1rem; }

.h7 {
  font-size: 0.9rem; }

/*
 *	HEADING FONT
 */
/*
 *	BODY FONT
 */
/*
 *	NAVBAR FONT
 */
.top-bar {
  font-family: 'Roboto Light'; }

/*
 *	ANCHORS
 */
a.underlined {
  text-decoration: underline; }

/*
 *	HEADING STYLES
 */
.highlight {
  font-family: 'Roboto Medium';
  color: #4c4c4c; }

.highlight-colourless {
  font-family: 'Roboto Medium'; }

.padding {
  padding: 1rem;
  line-height: 2rem; }

h1.background,
h2.background,
h3.background,
h4.background,
h5.background,
h6.background {
  width: 100%;
  height: 4rem;
  margin-bottom: 0;
  background: #e6ebeb;
  transition: background, 0.3s; }

h1.margin-top,
h2.margin-top,
h3.margin-top,
h4.margin-top,
h5.margin-top,
h6.margin-top,
p.margin-top {
  margin-top: 1rem; }

h1.margin-top-large,
h2.margin-top-large,
h3.margin-top-large,
h4.margin-top-large,
h5.margin-top-large,
h6.margin-top-large,
p.margin-top-large {
  margin-top: 2rem; }

h1.margin-bottom,
h2.margin-bottom,
h3.margin-bottom,
h4.margin-bottom,
h5.margin-bottom,
h6.margin-bottom,
p.margin-bottom {
  margin-bottom: 2rem; }

h1.margin-top-small,
h2.margin-top-small,
h3.margin-top-small,
h4.margin-top-small,
h5.margin-top-small,
h6.margin-top-small,
p.margin-top-small {
  margin-top: 0.25rem; }

span.margin-top-half {
  margin-top: 0.5rem; }

h1.margin-bottom-small,
h2.margin-bottom-small,
h3.margin-bottom-small,
h4.margin-bottom-small,
h5.margin-bottom-small,
h6.margin-bottom-small,
p.margin-bottom-small {
  margin-bottom: 1rem; }

h1.no-margin-bottom,
h2.no-margin-bottom,
h3.no-margin-bottom,
h4.no-margin-bottom,
h5.no-margin-bottom,
h6.no-margin-bottom,
p.no-margin-bottom {
  margin-bottom: 0; }

p.help-bigger {
  font-size: 0.875rem; }

h3.price {
  width: 100%;
  text-align: right;
  margin-bottom: 0; }
  @media screen and (max-width: 39.9375em) {
    h3.price {
      font-size: 1.9375rem; } }

h3.price-dark {
  line-height: 1.75rem;
  margin-bottom: 0; }
  @media screen and (max-width: 39.9375em) {
    h3.price-dark {
      text-align: left; } }

p.subtitle {
  width: 100%;
  text-align: right; }

p.hidden {
  display: none; }

.h6.italic {
  font-family: 'Roboto Light Italic'; }

/*
 *	CRUD HEADINGS
 */
h1.inline,
h2.inline,
h3.inline,
h4.inline,
h5.inline,
h6.inline {
  display: inline-block;
  margin-right: 1rem; }

/*
 *	HUMAN READABLE STRINGS
 */
span.faux_gap {
  display: inline-block;
  width: 0.2em; }

section#hero {
  color: white; }
  section#hero h1,
  section#hero h2,
  section#hero h3,
  section#hero h4,
  section#hero h5,
  section#hero h6,
  section#hero p {
    text-transform: uppercase; }
  section#hero h1.h5 {
    font-family: 'Roboto Bold';
    font-size: 1.625rem;
    color: #5ee0c9;
    margin-bottom: 1rem; }
  section#hero h2 {
    font-size: 2.875rem;
    line-height: 2.625rem; }
    section#hero h2 strong {
      font-family: 'Roboto Black'; }
  section#hero h3.h6 {
    font-family: 'Roboto Light';
    text-transform: none;
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin-top: 1rem; }
    @media print, screen and (min-width: 40em) {
      section#hero h3.h6 {
        font-family: 'Roboto'; } }
  @media print, screen and (min-width: 40em) {
    section#hero .shipping {
      text-align: right; } }
  section#hero .shipping h3.h4 {
    margin: 0;
    line-height: 1.875rem; }
    section#hero .shipping h3.h4 strong {
      display: block;
      font-family: 'Roboto Black'; }

/*
 *	ORDERS SECTION
 */
section#orders p.description {
  line-height: 1.875rem; }

section#orders p.blurb {
  font-size: 0.9375rem; }

section#orders h5.h6 {
  font-family: 'Roboto Medium';
  font-size: 1.125rem; }

p.small {
  font-size: 0.75rem; }

/*
 *	PRODUCTS SECTION
 */
p.associates {
  font-family: 'Roboto'; }

ul.menu {
  background: white; }

.menu li {
  transition: background-color, 0.3s; }

.menu li.is-submenu-item a {
  line-height: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.menu.dropdown li.opens-right .menu.vertical {
  left: -1px !important; }

.top-bar-left .menu.dropdown > li,
.top-bar-right .menu.dropdown > li:last-of-type {
  border-right: 1px solid #e6eded; }

.top-bar-right .menu.dropdown > li,
.top-bar-left .menu.dropdown > li:nth-of-type(2) {
  border-left: 1px solid #e6eded; }

@media print, screen and (min-width: 40em) {
  .menu.vertical.medium-horizontal {
    border: none; }
    .menu.vertical.medium-horizontal > li:not(:last-of-type) {
      border-bottom: none; } }

.menu li:hover {
  background: #fafafa; }

.menu.vertical {
  border: 1px solid #e6eded; }
  .menu.vertical li:not(:last-of-type) {
    border-bottom: 1px solid #e6eded; }
  .menu.vertical li form button {
    padding: 1.5rem 2rem;
    color: #0f9092; }

.menu.dropdown li.is-dropdown-submenu-parent a {
  padding-right: 3rem !important; }

.menu.dropdown li.is-dropdown-submenu-parent a::after {
  right: 2rem !important; }

/*
//	Curve
-----------------------------------
*/
/*
//	Prevent a FoUC on the responsive navigation
-----------------------------------
*/
@media screen and (max-width: 39.9375em) {
  .no-js .top-bar {
    display: none; } }

@media print, screen and (min-width: 40em) {
  .no-js .title-bar {
    display: none; } }

@media print, screen and (min-width: 64em) {
  table tr th,
  table:not(.row-links) tr td,
  table.row-links tr td a {
    padding: 1rem 1.25rem !important; } }

td.stock i {
  font-style: normal; }

table {
  border-collapse: collapse; }
  table thead th {
    font-family: 'Roboto Medium';
    font-weight: 400 !important; }
  table tr {
    transition: background, 0.1s; }
    table tr td a {
      display: block; }
    table tr td a:not(.external) {
      color: #4c4c4c; }
    table tr td input[type="number"] {
      height: 2.5rem !important; }

table.row-links tr td {
  padding: 0; }
  table.row-links tr td a {
    height: 1.5rem;
    box-sizing: content-box;
    padding: 0.75rem; }

table.row-links tr td:hover a {
  color: #4c4c4c; }

table.data th:not(:last-of-type) {
  border-right: 1px solid #ededed; }

table.data th:hover {
  background: #f0f0f0; }

table.data td:not(:last-of-type) {
  border-right: 1px solid #ededed; }

table.data tr:hover td:not(:last-of-type) {
  border-right: 1px solid #e3e3e3; }

table.dataTable thead .sorting::before {
  float: right;
  clear: both;
  position: relative;
  top: 0.375rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 5px;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #b2b2b2; }

table.dataTable thead .sorting::after {
  float: right;
  clear: both;
  position: relative;
  top: 0.5rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 5px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #b2b2b2 transparent transparent; }

table.dataTable thead .sorting_asc::after {
  float: right;
  position: relative;
  top: 0.375rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 5px;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0f9092; }

table.dataTable thead .sorting_desc::after {
  float: right;
  position: relative;
  top: 0.8125rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 5px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0f9092 transparent transparent; }

table.dataTable thead .sorting_asc_disabled::after {
  float: right;
  position: relative;
  top: 0.375rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 5px;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #b2b2b2; }

table.dataTable thead .sorting_desc_disabled::after {
  float: right;
  position: relative;
  top: 0.375rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 5px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #b2b2b2 transparent transparent; }

div.column.filters button, div.filters.columns button {
  line-height: 1.4rem; }

button span.badge {
  position: relative;
  top: -1px;
  margin-left: 0.2rem;
  line-height: 0.9rem;
  font-family: 'Roboto Medium'; }

button:not(.hollow) span.badge {
  color: #0f9092;
  background: white; }

table#table_orders td.shipped a {
  color: #3adb76; }

table#table_orders td.cancelled a {
  color: #cc4b37; }

td.tracking_link:not(:first-of-type) {
  border-left: 1px solid #ededed; }

tr:hover td.tracking_link:not(:first-of-type) {
  border-left: 1px solid #e3e3e3; }

/*
 * Stock indicators.
 */
span.stock i:not(.material-icons) {
  position: relative;
  top: -5px; }

span.stock_ok {
  color: #0f9092; }

span.stock_low {
  color: #7f7f7f; }

span.stock_out {
  color: #cc4b37; }

tr.stock_out {
  background: #cc4b37 !important;
  color: white; }

@media print, screen and (min-width: 64em) {
  #table_items {
    margin-top: 0; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em&uhd=120.0625em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #f2f2f2;
  font-family: "Roboto Light";
  font-weight: normal;
  line-height: 1.5;
  color: #4c4c4c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #0f9092;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #0d7c7e; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #4c4c4c;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #ffffff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #f2f2f2; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #ffffff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0f9092;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #0d7a7c;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #0f9092;
    color: #0a0a0a; }
    .button.primary:hover, .button.primary:focus {
      background-color: #0c7375;
      color: #0a0a0a; }
  .button.secondary {
    background-color: #767676;
    color: #ffffff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #ffffff; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #ffffff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #ffffff; }
  .button.hollow {
    border: 1px solid #0f9092;
    color: #0f9092; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #084849;
      color: #084849; }
    .button.hollow.primary {
      border: 1px solid #0f9092;
      color: #0f9092; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #084849;
        color: #084849; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
      background-color: #0f9092;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #0f9092;
        color: #ffffff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #ffffff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #ffffff; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #ffffff; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #ffffff; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

.accordion {
  margin-left: 0;
  background: #ffffff;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #0f9092; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '–'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #ffffff;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0f9092 transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #0f9092;
  color: #ffffff; }
  .badge.primary {
    background: #0f9092;
    color: #0a0a0a; }
  .badge.secondary {
    background: #767676;
    color: #ffffff; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #ffffff; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #0f9092; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #0f9092;
    color: #0a0a0a; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #0c7375;
      color: #0a0a0a; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #ffffff; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #ffffff; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #ffffff; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #ffffff; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid #cecece;
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d0f9fa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #ffffff;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 1.5rem 2rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    margin-right: 2rem;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #0f9092;
    color: #ffffff; }
  .menu.menu-bordered li {
    border: 1px solid 1px solid #e6eded; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 1.5rem 2rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #ffffff;
    box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #ffffff;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0f9092;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0f9092 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #f2f2f2;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0f9092 transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0f9092 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0f9092; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0f9092 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0f9092 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0f9092; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0f9092 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0f9092 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0f9092; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #ffffff; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0f9092 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0f9092; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed, .flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video, .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen, .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #0f9092;
  color: #ffffff; }
  .label.primary {
    background: #0f9092;
    color: #0a0a0a; }
  .label.secondary {
    background: #767676;
    color: #ffffff; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #ffffff; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(250px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-250px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #ffffff; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #ffffff; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #0f9092;
    color: #ffffff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #0f9092; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #0f9092; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #0f9092;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #0d7a7c; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns,
  .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #ffffff;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #0f9092; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #cecece;
    background-color: #ffffff; }
  caption {
    padding: 0.75rem;
    font-weight: bold; }
  thead {
    background: #fcfcfc;
    color: #4c4c4c; }
  tfoot {
    background: #fafafa;
    color: #4c4c4c; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.75rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.75rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #fafafa; }
  table.unstriped tbody {
    background-color: #ffffff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #cecece;
      background-color: #ffffff; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #fafafa; }

table.hover tfoot tr:hover {
  background-color: #f7f7f7; }

table.hover tbody tr:hover {
  background-color: #fcfcfc; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #f7f7f7; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #0f9092; }
  .tabs.primary > li > a {
    color: #0a0a0a; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #11a0a2; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #0f9092; }
    .tabs-title > a:hover {
      background: #ffffff;
      color: #0d7c7e; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #0f9092; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #ffffff;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #ffffff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(15, 144, 146, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #ffffff; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #ffffff; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #ffffff; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

/*
-------------------------------------------------------------------------------------
//	UTILITY
-------------------------------------------------------------------------------------
*/
/*
//	Anchors
-----------------------------------
*/
a {
  outline: none; }

/*
//	Shadows
-----------------------------------
*/
/*
//	Columns
-----------------------------------
*/
.column.collapse, .collapse.columns {
  padding-left: 0;
  padding-right: 0; }

.column.padded, .padded.columns {
  padding: 0 3rem; }
  @media print, screen and (min-width: 64em) {
    .column.padded, .padded.columns {
      padding: 0 8rem; } }
  @media screen and (max-width: 39.9375em) {
    .column.padded, .padded.columns {
      padding: 0 1rem; } }

/*
//	Margins
-----------------------------------
*/
.margin-bottom-none {
  margin-bottom: 0 !important; }

.margin-bottom-half {
  margin-bottom: 0.5rem !important; }

.margin-bottom {
  margin-bottom: 1rem !important; }

.margin-bottom-double {
  margin-bottom: 2rem !important; }

.margin-bottom-quadruple {
  margin-bottom: 4rem !important; }

.margin-top-none {
  margin-top: 0 !important; }

.margin-top-half {
  margin-top: 0.5rem !important; }

.margin-top {
  margin-top: 1rem !important; }

.margin-top-double {
  margin-top: 2rem !important; }

.margin-top-quadruple {
  margin-top: 4rem !important; }

@media screen and (max-width: 39.9375em) {
  .margin-top-small {
    margin-top: 1rem !important; } }

@media screen and (max-width: 63.9375em) {
  .margin-top-medium {
    margin-top: 1rem !important; } }

/*
//	Padding
-----------------------------------
*/
.padding-top-half {
  padding-top: 0.5rem; }

.padding-top {
  padding-top: 1rem; }

.padding-top-double {
  padding-top: 2rem; }

.padding-top-quadruple {
  padding-top: 4rem; }

.padding-bottom-half {
  padding-bottom: 0.5rem; }

.padding-bottom {
  padding-bottom: 1rem; }

.padding-bottom-double {
  padding-bottom: 2rem; }

.padding-bottom-quadruple {
  padding-bottom: 4rem; }

/*
//	Sections
-----------------------------------
*/
section.white {
  background: white;
  box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, 0.06); }

section.padded {
  padding: 1rem 0; }
  @media print, screen and (min-width: 40em) {
    section.padded {
      padding: 2rem 0; } }

section.delineated {
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece; }

section.delineated-top {
  border-top: 1px solid #cecece; }

section.delineated-bottom {
  border-bottom: 1px solid #cecece; }

section.shaded {
  box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, 0.06); }

/*
//	Divs
-----------------------------------
*/
.box {
  border: 1px solid #cecece;
  transition: background-color, 0.3s;
  background: white; }

.box.padded {
  padding: 2rem 1.5rem 1rem 1.5rem !important; }

.box_hollow {
  border: 1px solid #cecece;
  transition: background-color, 0.3s; }

.box_attached {
  border: 1px solid #cecece;
  transition: background-color, 0.3s;
  background: white;
  border-top: 0;
  padding: 1rem 2rem; }

.box.hover:hover {
  background: #f7f7f7; }

@media print, screen and (min-width: 40em) {
  .border-right-medium {
    border-right: 1px solid #cdcdcd; } }

/*
//	Images
-----------------------------------
*/
/*
//	Floats
-----------------------------------
*/
@media screen and (min-width: 40em) {
  .float-right-medium {
    float: right; } }

@media screen and (min-width: 64em) {
  .float-right-large {
    float: right; } }

/*
//	Navigation
-----------------------------------
*/
#main-nav {
  position: relative;
  z-index: 3; }
  @media screen and (max-width: 39.9375em) {
    #main-nav {
      margin-top: -0.5rem;
      margin-top: -2rem;
      background: white; }
      #main-nav .top-bar-right {
        margin-bottom: 1rem; }
      #main-nav ul#top li:first-of-type {
        padding-top: 1.5rem; } }

#main-nav-shadow {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 4rem;
  background: white;
  box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, 0.1); }

#main-nav-background {
  position: absolute;
  z-index: -2;
  left: 0;
  top: 0;
  width: 100%;
  height: 5.5rem; }

@media screen and (max-width: 39.9375em) {
  #main-nav ul.menu {
    background: white;
    border-top: 0;
    border-right: 0;
    border-left: 0; }
  #main-nav ul.menu li:hover {
    background: #fafafa; }
  #main-nav ul.menu ul.menu {
    background: #fafafa; }
  #main-nav ul.menu ul.menu li:hover {
    background: whitesmoke; } }

#main-nav-shadow + ul.menu {
  margin-top: -4rem; }

.top-bar {
  height: 4rem; }
  .top-bar .main-logo {
    float: left;
    clear: none;
    padding: 1rem 4rem 1.3125rem 100rem;
    margin-left: -100rem;
    background-image: url("../img/nav-curve.svg");
    background-position: top right;
    background-repeat: repeat-x;
    overflow: visible; }
  .top-bar li.menu-text a {
    padding: 0; }

.title-bar {
  height: 4rem;
  padding: 0;
  background: white;
  color: #0f9092; }
  .title-bar .main-logo {
    float: left;
    clear: none;
    padding: 1rem 4rem 1.3125rem 10rem;
    margin-left: -10rem;
    background-image: url("../img/nav-curve.svg");
    background-position: top right;
    background-repeat: repeat-x;
    overflow: visible;
    position: relative;
    z-index: 5; }
  .title-bar .title-bar-right {
    position: relative;
    z-index: 6; }
    .title-bar .title-bar-right #title-bar-button {
      float: right;
      clear: none;
      width: 8rem;
      padding: 1.25rem 1rem;
      border-left: 1px solid #e6eded; }
      .title-bar .title-bar-right #title-bar-button i {
        margin-left: 0.5rem; }

#title-bar-shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 4rem;
  box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, 0.1);
  z-index: 4; }

.sweet-overlay {
  background: rgba(10, 10, 10, 0.6); }

.sweet-alert {
  font-family: 'Roboto';
  padding: 1.5rem;
  padding-top: 4rem;
  padding-bottom: 0.8125rem;
  text-align: right;
  border-radius: 0;
  box-shadow: 0 0 0.875rem rgba(0, 0, 0, 0.24), 0 0.875rem 1.75rem rgba(0, 0, 0, 0.48); }
  .sweet-alert h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    font-weight: 400;
    color: #212121;
    margin: 1.25rem 0;
    font-size: 1.2em;
    line-height: 1.25;
    text-align: left;
    padding: 0 1.5rem; }
  .sweet-alert p {
    display: block;
    text-align: center;
    color: #212121;
    font-weight: 400;
    font-size: 0.875rem;
    margin: 1.25rem 0; }
  .sweet-alert button {
    position: relative;
    margin-top: 0;
    padding: 0.75rem 1.5rem;
    border-radius: 0;
    box-shadow: none !important;
    font-family: 'Roboto Medium';
    font-size: 0.875rem;
    text-transform: uppercase;
    transition: background, 0.3s; }
  .sweet-alert button.cancel {
    background: #808080; }
  .sweet-alert button.cancel:hover {
    background: #737373; }
  .sweet-alert button.confirm:hover {
    background: #0d797b !important; }
  .sweet-alert .sa-icon:not(.sa-custom) {
    transform: scale(0.8);
    margin-bottom: -0.625rem;
    margin-top: -0.625rem; }
  .sweet-alert input {
    border: none;
    border-radius: 0;
    border-bottom: 0.0625rem solid #c9c9c9;
    color: #212121;
    margin-bottom: 0.5rem;
    padding: 0.0625rem;
    padding-bottom: 0.5rem;
    height: auto;
    box-shadow: none;
    font-size: 0.8125rem;
    margin: 0.625rem 0; }
    .sweet-alert input:focus {
      border: none;
      border-bottom: 0.0625rem solid #3c80f6;
      box-shadow: inset 0 -0.0625rem 0 #3c80f6; }
  .sweet-alert fieldset {
    padding: 0; }
    .sweet-alert fieldset .sa-input-error {
      display: none; }
  .sweet-alert .sa-error-container {
    display: none;
    background: none;
    height: auto;
    padding: 0 1.5rem;
    margin: 0 -1.25rem;
    text-align: left; }
    .sweet-alert .sa-error-container.show {
      padding: 0 1.5rem;
      display: block; }
      .sweet-alert .sa-error-container.show ~ fieldset input {
        background: red;
        border-bottom: 0.0625rem solid #d9453c;
        box-shadow: inset 0 -0.0625rem 0 #d9453c; }
    .sweet-alert .sa-error-container .icon {
      display: none; }
    .sweet-alert .sa-error-container p {
      color: #d9453c;
      margin-top: 0; }

@-webkit-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg), scale(0.5);
    -webkit-transform: rotateX(100deg), scale(0.5);
    opacity: 0; }
  100% {
    transform: rotateX(0deg), scale(0.5);
    -webkit-transform: rotateX(0deg), scale(0.5);
    opacity: 1; } }

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg), scale(0.5);
    -webkit-transform: rotateX(100deg), scale(0.5);
    opacity: 0; }
  100% {
    transform: rotateX(0deg), scale(0.5);
    -webkit-transform: rotateX(0deg), scale(0.5);
    opacity: 1; } }

#breadcrumbs {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  #breadcrumbs .trail {
    margin: 0;
    overflow: hidden;
    list-style: none; }
    #breadcrumbs .trail li {
      float: left;
      border: 1px solid #d9d9d9; }
      #breadcrumbs .trail li a {
        text-decoration: none;
        display: block;
        position: relative;
        float: left;
        padding: 10px 15px 10px 30px;
        background: #f7f7f7;
        transition: background-color, 0.2s; }
      #breadcrumbs .trail li a:after {
        content: "";
        display: block;
        position: absolute;
        left: 100%;
        top: 50%;
        width: 0;
        height: 0;
        margin-top: -24px;
        border-top: 24px solid transparent;
        border-bottom: 24px solid transparent;
        border-left: 15px solid #f7f7f7;
        transition: background-color, 0.2s;
        z-index: 2; }
      #breadcrumbs .trail li a:before {
        content: "";
        display: block;
        position: absolute;
        left: 100%;
        top: 50%;
        width: 0;
        height: 0;
        margin-top: -24px;
        margin-left: 1px;
        border-top: 24px solid transparent;
        border-bottom: 24px solid transparent;
        border-left: 15px solid #d9d9d9;
        z-index: 1; }
      #breadcrumbs .trail li a:hover {
        background: #efefef; }
      #breadcrumbs .trail li a:hover:after {
        border-left-color: #efefef; }
    #breadcrumbs .trail li:last-child a {
      pointer-events: none;
      cursor: default;
      color: #737373; }

.button i {
  position: relative;
  float: left; }

.button.border-thick {
  border: 0.125rem solid #0f9092 !important;
  border-radius: 0.125rem;
  font-size: 1rem; }

.button.inline {
  border: 0.125rem solid #0f9092 !important;
  border-radius: 0.125rem;
  font-size: 1rem; }
  @media print, screen and (min-width: 40em) {
    .button.inline {
      margin-bottom: 0; } }

.button.inline:hover {
  border-color: #0d797b !important; }

.button.wide {
  width: 100%; }

.button.wide-half {
  width: 50%; }

@media print, screen and (min-width: 40em) {
  .button.medium-wide-half {
    width: 50%; } }

@media print, screen and (min-width: 64em) {
  .button.large-wide-half {
    width: 50%; } }

@media print, screen and (min-width: 40em) {
  .button.medium-narrow {
    width: auto; } }

.button.tall {
  height: 3.5rem;
  font-family: 'Open Sans';
  font-size: 1rem;
  line-height: 1.675rem; }
  @media print, screen and (min-width: 40em) {
    .button.tall {
      font-size: 1.1875rem;
      line-height: 1.2rem; } }

.button.bold {
  font-family: 'Roboto Medium';
  font-size: 1rem !important; }

.button.uppercase {
  text-transform: uppercase; }

.button.padded {
  padding-left: 3rem !important;
  padding-right: 3rem !important; }

.button.padded-small {
  padding-left: 2rem !important;
  padding-right: 2rem !important; }

.button.crud {
  margin-bottom: 1rem; }

@media screen and (max-width: 39.9375em) {
  .button.crud:not(:last-of-type) {
    margin-right: 0.5rem; } }

@media print, screen and (min-width: 40em) {
  .button.crud:not(:first-of-type) {
    margin-right: 0.5rem; } }

.button.labeled {
  line-height: 1.5rem; }
  .button.labeled i {
    margin-right: 0.25rem; }

.button.crud.secondary {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  color: #7f7f7f; }

.button.crud.secondary:hover {
  background: #ededed;
  color: #666666; }

.button.crud.hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute; }

.button.crud.move {
  margin: 0 0 0 1rem; }

input.button-prefix:hover + i {
  background: #0d797b;
  border-color: #0d797b; }

td.tracking_link .button {
  width: auto;
  margin: 0rem;
  padding: 0.5rem; }

.total {
  border: 1px solid #cecece;
  transition: background-color, 0.3s;
  background: white;
  padding: 2rem 1.5rem 1rem 1.5rem !important; }
  .total a:not(.button) {
    color: #4c4c4c;
    transition: color, 0.2s; }
    .total a:not(.button) ul {
      list-style: none;
      margin: 0;
      font-size: 0.9375rem; }
  .total a:not(.button):hover {
    color: #0f9092; }
    .total a:not(.button):hover i {
      opacity: 1; }

.total-dark {
  background: #4c4c4c;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #4c4c4c; }

.total-dark * {
  color: white; }

legend.checkout {
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.125rem; }
  legend.checkout i {
    position: relative;
    top: 0.375rem; }

label.radio {
  border: 1px solid #cecece;
  transition: background-color, 0.3s;
  background: white;
  width: 100%;
  padding: 1rem 1.5rem 0.75rem;
  color: #4c4c4c;
  cursor: pointer; }

div.radio {
  border: 1px solid #cecece;
  transition: background-color, 0.3s;
  background: white;
  width: 100%;
  padding: 1rem 1.5rem 0.75rem;
  color: #4c4c4c; }
  div.radio label.hover {
    cursor: pointer; }

div.radio.dark {
  background-color: #f2f2f2; }

div.radio.hidden {
  display: none;
  margin-top: 1.5rem; }

div.hidden_fields {
  display: none; }

div.radio.disabled,
div.radio.disabled label.hover {
  cursor: not-allowed; }

.shipping-help,
.delivery-help {
  display: none; }

.delivery-help {
  transition: height, 0.3s;
  margin-bottom: 0; }

#hours {
  padding: 2rem 1.5rem;
  color: white; }

.open {
  background: #0f9092; }

.closed {
  background: #ff8e50; }

a.contact_number.disabled {
  pointer-events: none;
  color: #4c4c4c; }

#map {
  width: 100%;
  height: 9rem;
  background-image: url("../img/map.jpg");
  background-position: center; }

.flashdata {
  padding-top: 1rem; }
  @media print, screen and (min-width: 64em) {
    .flashdata {
      padding-bottom: 1rem; } }
  .flashdata .callout {
    padding: 0.75rem 1rem;
    margin-bottom: 0; }

.flashdata.persistent {
  padding-top: 1rem; }
  @media print, screen and (min-width: 64em) {
    .flashdata.persistent {
      padding-top: 3.25rem;
      min-height: 7.625rem; } }

.notstatic {
  position: relative; }

.floating {
  position: absolute;
  width: 100%;
  margin-top: 1rem;
  min-height: 4.625rem;
  background: orange; }

/*
-------------------------------------------------------------------------------------
//	FOOTER
-------------------------------------------------------------------------------------
*/
html {
  position: relative !important;
  min-height: 100% !important; }

body, html {
  height: auto !important; }

body.footer {
  margin-bottom: 10rem;
  display: block !important; }
  @media print, screen and (min-width: 40em) {
    body.footer {
      margin-bottom: 8.5rem; } }
  @media print, screen and (min-width: 64em) {
    body.footer {
      margin-bottom: 7rem; } }

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 9rem;
  padding-top: 0.8125rem;
  background: white; }
  @media print, screen and (min-width: 40em) {
    footer {
      height: 6.5rem; } }
  @media print, screen and (min-width: 64em) {
    footer {
      height: 4rem; } }
  footer p {
    margin-bottom: 0; }
  footer .footer-left {
    line-height: 1rem;
    padding-top: 0.5rem; }
    footer .footer-left p {
      display: inline-block;
      font-size: 0.75rem;
      color: #727272; }
  @media screen and (max-width: 39.9375em) {
    footer .footer-right {
      margin-bottom: 0.5rem; } }
  footer .footer-right p {
    font-family: 'Open Sans Bold Italic';
    font-size: 0.6875rem;
    color: #a5a5a5; }
    footer .footer-right p img {
      margin-right: 0.5rem; }

.input-group-label.spaced {
  min-width: 4rem; }

.required {
  color: red; }

input[type="text"],
input[type="number"],
input[type="password"] {
  padding-right: 1rem; }

input[type="text"],
input[type="number"],
input[type="password"],
select {
  height: 3rem;
  padding-left: 1rem;
  font-family: 'Roboto';
  color: #595959; }

select option {
  background: #fcfcfc;
  padding: 0.75rem 1rem; }

input.tall,
select.tall {
  height: 3.5rem; }

option.blank {
  color: #a5a5a5; }

label.hover {
  cursor: pointer; }

/*
 * Logout form.
 */
form#logout button:hover {
  cursor: pointer; }

/*
 * Input placeholders.
 */
input::-webkit-input-placeholder {
  color: #c7c7c7 !important; }

input:-moz-placeholder {
  color: #c7c7c7 !important;
  opacity: 1; }

input::-moz-placeholder {
  color: #c7c7c7 !important;
  opacity: 1; }

input:-ms-input-placeholder {
  color: #c7c7c7 !important; }

#product_create input[type="checkbox"] + label,
#product_edit input[type="checkbox"] + label {
  margin-left: 0;
  margin-right: 1.5rem;
  padding-left: 0.25rem;
  padding-right: 0; }

/*
 * Quantity inputs.
 */
input[type="number"].quantity {
  width: 4rem; }
  @media print, screen and (min-width: 40em) {
    input[type="number"].quantity {
      width: 6rem; } }

input[type="number"].stock {
  width: 8rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem; }

/*
 * Fieldsets.
 */
.fieldset.mobile {
  margin-top: 0.125rem;
  padding-bottom: 1.8125rem; }
  .fieldset.mobile legend {
    font-size: 0.875rem;
    color: #0a0a0a;
    background: transparent; }

.box .fieldset {
  margin-top: 0; }
  .box .fieldset legend {
    background: transparent; }

/*
 * Error classes.
 */
i.hover {
  color: #0f9092;
  opacity: 0;
  transition: opacity, 0.2s; }

i.button-prefix {
  padding: 0.85rem 0 0.85rem 2rem;
  cursor: default;
  color: white;
  line-height: 1rem;
  background: #0f9092;
  border: 1px solid #0f9092;
  transition: background, 0.3s; }

i.button-prefix:hover {
  background: #0d797b; }

.pointer {
  cursor: pointer; }

i.left {
  margin-right: 0.5rem; }

i.right {
  margin-left: 0.5rem; }

i.top {
  position: relative;
  top: 0.3125rem; }

i.alert {
  color: #cc4b37; }

i.navbar {
  line-height: 0; }

i.status {
  margin-left: 0.25rem;
  line-height: 1rem;
  font-size: 1.375rem;
  color: #ff8e65; }

.user-details li.name::before {
  content: "account_circle"; }

.user-details li.company::before {
  content: "work"; }

.user-details li.email::before {
  content: "mail"; }

.user-details li.email_secondary::before {
  content: "mail_outline"; }

.user-details li.phone::before {
  content: "phone"; }

.user-details li.date::before {
  content: "event"; }

.user-details li.password::before {
  content: "vpn_key"; }

.user-details li.bank_name::before {
  content: "account_balance"; }

.user-details li.bank_branch::before {
  content: "place"; }

.user-details li.bank_account_name::before {
  content: "account_box"; }

.user-details li.bank_account_number::before {
  content: "monetization_on"; }

.user-details li.gst::before {
  content: "business_center"; }

.user-addresses i {
  color: #0f9092; }

.user-addresses h2.background:hover i {
  color: white; }

/*
 * List item icons.
 */
li.icon::before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  position: relative;
  top: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.3125rem; }

li.icon.margin-half::before {
  margin-right: 0.5rem; }

li.merge:not(:first-of-type)::before {
  visibility: hidden; }

li.icon.phone_landline::before {
  content: "phone"; }

li.icon.phone_mobile::before {
  content: "smartphone"; }

li.icon.eaddress::before {
  content: "mail_outline"; }

li.icon.clock::before {
  content: "schedule"; }

li.icon.pin::before {
  content: "place"; }

li.icon.tick::before {
  content: "check"; }

li.icon.cross::before {
  content: "clear"; }

.feature {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem; }
  .feature .border {
    width: 9.5rem;
    height: 9.5rem;
    border-radius: 4.75rem;
    line-height: 9.5rem;
    padding-top: 0.25rem;
    width: calc(9rem + 2px);
    height: calc(9rem + 2px);
    border-radius: calc(4.5rem + 2px);
    line-height: calc(9rem + 2px);
    padding-top: calc(0.5px + 0.5px);
    margin: auto;
    background: #dddddd; }
    .feature .border i {
      width: 9rem;
      height: 9rem;
      border-radius: 4.5rem;
      line-height: 9rem;
      text-align: center;
      font-size: 5.5rem;
      color: #6b6b6b;
      background: white; }

.feature *:hover {
  cursor: default; }

.container-image {
  position: relative;
  max-height: 25rem;
  overflow: hidden;
  transition: background, 0.2s; }

.container-image.admin {
  border: 1px solid #cdcdcd; }
  .container-image.admin .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity, 0.2s;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    opacity: 0; }
    .container-image.admin .overlay h5 {
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      height: 2rem;
      margin-top: -1rem;
      margin-bottom: 0;
      font-size: 1.125rem; }
      .container-image.admin .overlay h5 i {
        position: relative;
        left: 0.125rem;
        top: 0.25rem; }

.container-image.admin:hover .overlay {
  opacity: 1; }

div.thumbnail.primary {
  padding-bottom: 1rem !important; }

div.thumbnail.primary::after {
  position: relative;
  top: 0.4375rem;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  content: "star";
  color: #0f9092; }

div.thumbnail.primary.selected::after {
  color: white; }

.grid img {
  cursor: pointer; }

ul.unstyled {
  list-style: none; }

ul.margin-x-none {
  margin-left: 0;
  margin-right: 0; }

ul.margin-none {
  margin: 0; }

ul.address {
  list-style: none;
  margin-left: 0;
  margin-right: 0;
  font-family: 'Roboto Light';
  font-size: 0.9375rem;
  color: #4c4c4c;
  line-height: 1.5rem; }

ul.position {
  display: table;
  width: 100%;
  margin-left: 0;
  list-style: none; }
  ul.position .buttons {
    float: right;
    min-width: 7.85rem;
    background: orange; }
  ul.position li {
    border: 1px solid #cecece;
    transition: background-color, 0.3s;
    background: white;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem; }

ul.short li a {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

ul.margin-top,
li.margin-top {
  margin-top: 1rem; }

ul.margin-top-half,
li.margin-top-half {
  margin: 0.5rem 0 0; }

ul.padded {
  padding: 1.5rem !important; }

ul.padded-taller {
  padding: 1.75rem 1.5rem !important; }

ul.phone {
  font-size: 1.1rem; }

ul.phone li.icon::before {
  top: 0.25rem; }

ul.inline li {
  display: inline-block; }

ul.inline li:not(:first-of-type) {
  margin-left: 1rem; }

ul.inline li::before {
  color: #0f9092; }

ul.totals {
  font-size: 1rem;
  line-height: 2rem;
  margin: 0; }
  ul.totals li.large {
    font-family: 'Roboto Light';
    font-size: 1.75rem; }

/*
//	Terms and Conditions
-----------------------------------
*/
#term-links {
  display: none; }
  #term-links li a.active {
    color: white;
    background: #0f9092; }

#terms-sections ol#clauses li.clause {
  padding-top: 1.5rem;
  margin-bottom: 1px; }

@media print, screen and (min-width: 40em) {
  .row.category .column.category .outer, .row.category .category.columns .outer {
    min-height: 32rem; } }

.row.category .column.category .outer .top, .row.category .category.columns .outer .top {
  transition: background, 0.3s; }

.row.category .column.category .outer .bottom, .row.category .category.columns .outer .bottom {
  padding: 0.5rem 1.5rem; }
  @media print, screen and (min-width: 40em) {
    .row.category .column.category .outer .bottom, .row.category .category.columns .outer .bottom {
      padding: 1rem 2rem; } }
  .row.category .column.category .outer .bottom h2, .row.category .category.columns .outer .bottom h2 {
    margin: 0;
    font-size: 1.125rem; }
  .row.category .column.category .outer .bottom p, .row.category .category.columns .outer .bottom p {
    color: #4c4c4c;
    line-height: 1.875rem; }

.row.category .column.category .outer:hover, .row.category .category.columns .outer:hover {
  background: #f7f7f7; }

p.product-description {
  font-size: 1.2rem;
  margin-bottom: 2rem; }

.product {
  border: 1px solid #cecece;
  transition: background-color, 0.3s;
  background: white;
  margin-bottom: 2rem; }
  .product .product-image {
    background: #e7e7e7; }
  .product div.product-description {
    padding: 5rem 4rem 3rem 4rem;
    color: #4c4c4c; }
    .product div.product-description h3 {
      margin-bottom: 1rem; }

a:hover .product {
  background: #f7f7f7; }

.menu.vertical.profile {
  font-family: 'Roboto Medium'; }

.user-details {
  margin-bottom: 1rem;
  border: 1px solid #cecece;
  transition: background-color, 0.3s;
  background: white; }
  .user-details a {
    display: block; }
  .user-details ul:not(.address) {
    list-style: none;
    color: #4c4c4c;
    padding-bottom: 1rem;
    margin-bottom: 0; }
    .user-details ul:not(.address) li {
      height: 2.875rem; }
  .user-details ul li::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    position: relative;
    top: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.3125rem;
    color: #8c8c8c; }
  .user-details .row {
    padding: 1rem; }

.user-details:not(.no-hover):hover {
  background: #f7f7f7; }
  .user-details:not(.no-hover):hover i {
    opacity: 1; }

.user-addresses {
  background: white;
  border: 1px solid #cecece;
  padding: 0; }
  .user-addresses h1.background a,
  .user-addresses h2.background a,
  .user-addresses h3.background a,
  .user-addresses h4.background a,
  .user-addresses h5.background a,
  .user-addresses h6.background a {
    display: block;
    padding: 1rem;
    line-height: 2rem;
    color: #4c4c4c;
    transition: background, 0.2s; }
  .user-addresses h1.background a:hover,
  .user-addresses h2.background a:hover,
  .user-addresses h3.background a:hover,
  .user-addresses h4.background a:hover,
  .user-addresses h5.background a:hover,
  .user-addresses h6.background a:hover {
    color: white;
    background: #0f9092; }
  .user-addresses a.hover-box {
    display: block;
    padding: 1rem 2rem; }
    .user-addresses a.hover-box h2 {
      line-height: 2rem; }
  .user-addresses a.hover-box:hover {
    background: #f7f7f7;
    transition: background-color, 0.3s; }
    .user-addresses a.hover-box:hover i {
      opacity: 1; }
  .user-addresses hr {
    width: 90%;
    margin: 0 auto;
    transition: width, 0.2s; }
  .user-addresses hr:last-of-type {
    display: none; }

.user-addresses:hover hr {
  width: 100%; }

section#hero {
  width: 100%;
  min-height: 28.125rem;
  margin: 0;
  margin: -2rem 0 0; }
  section#hero .headings {
    margin-top: 1rem; }
    @media print, screen and (min-width: 40em) {
      section#hero .headings {
        margin-top: 3rem; } }
    @media print, screen and (min-width: 64em) {
      section#hero .headings {
        margin-top: 1.75rem; } }
  section#hero .shipping {
    margin-bottom: 2rem; }
    section#hero .shipping .background {
      width: auto;
      max-width: 21rem;
      padding: 1.25rem 1.5rem 1rem 1.5rem;
      background: rgba(0, 0, 0, 0.3); }
      @media print, screen and (min-width: 64em) {
        section#hero .shipping .background {
          float: right; } }
      @media print, screen and (min-width: 40em) {
        section#hero .shipping .background h3.h4,
        section#hero .shipping .background p {
          float: right;
          clear: none; } }
      section#hero .shipping .background p {
        font-size: 0.85938rem;
        margin-bottom: 0; }
      section#hero .shipping .background .new_zealand {
        margin-right: 0.75rem;
        float: left;
        clear: none; }
        @media print, screen and (min-width: 40em) {
          section#hero .shipping .background .new_zealand {
            float: right;
            margin-right: 0;
            margin-left: 0.75rem; } }
    section#hero .shipping .background::after {
      content: "";
      display: block;
      clear: both; }

section#orders .row.features {
  margin-top: 1rem; }

section#quality .highlight p {
  font-family: 'Roboto Light Italic';
  font-size: 1.5rem;
  color: #0f9092; }

section#quality p {
  line-height: 2rem; }

section#associates {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.04) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.04) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.04) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f000000', endColorstr='#0a000000',GradientType=0 ); }
  section#associates #logos_associates {
    position: relative; }
    section#associates #logos_associates img {
      padding: 0 0.5rem; }

ul.thumbnails.image_picker_selector li {
  min-width: 12.5rem;
  width: 24.375%;
  margin-bottom: 0.75rem; }

ul.thumbnails.image_picker_selector li .thumbnail {
  padding: 0.25rem;
  margin-bottom: 0 !important; }

ul.thumbnails.image_picker_selector li .thumbnail.selected {
  background: #0f9092;
  border-color: #0f9092; }

.image_picker_image:hover {
  cursor: pointer; }
