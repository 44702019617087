// $table-background: orange;
// $table-border: 3px solid purple;
// $table-border: 1px solid #d9d9d9;
// $table-border: 10px solid red !important;
// $table-padding: 1rem 1.5rem;
// $table-head-row-hover: $table-head-background;
// $table-head-font-color: $body-font-color;
// $table-striped-background: darken(white, 2%);
// $table-color-scale: 5%;
// $table-hover-scale: 1%;
// $table-row-hover: darken($table-background, $table-color-scale + $table-hover-scale);
// $table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);

$table-background: $white;
$table-color-scale: 2%;
$table-border: 1px solid #cecece;
$table-padding: 0.75rem;
$table-hover-scale: 1%;
$table-row-hover: darken($table-background, $table-hover-scale);
$table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
$table-is-striped: true;
$table-striped-background: smart-scale($table-background, $table-color-scale);
$table-stripe: even;
$table-head-background: smart-scale($table-background, $table-color-scale / 2);
$table-head-row-hover: darken($table-head-background, $table-hover-scale);
$table-foot-background: smart-scale($table-background, $table-color-scale);
$table-foot-row-hover: darken($table-foot-background, $table-hover-scale);
$table-head-font-color: $body-font-color;
$table-foot-font-color: $body-font-color;
$show-header-for-stacked: false;

// @mixin table-padding-medium()
// {
// 	padding: 0.75rem 0.75rem !important;
// }
// @mixin table-padding-large()
// {
// 	padding: 0.75rem 1.25rem !important;
// }

// @mixin table-padding()
// {
// 	@include breakpoint(medium)
// 	{
// 		padding: 0.75rem !important;
// 	}
// 	@include breakpoint(large)
// 	{
// 		padding: 1rem 1.25rem !important;
// 	}
// }

@include breakpoint(large)
{
	table tr th,
	table:not(.row-links) tr td,
	table.row-links tr td a
	{
		padding: 1rem 1.25rem !important;
	}
}

td.stock i
{
	font-style: normal;
}

table
{
	border-collapse: collapse;
	
	thead
	{
		th
		{
			// @include table-padding();
			
			font-family: 'Roboto Medium';
			font-weight: 400 !important;
		}
	}
	
	tr
	{
		transition: background, 0.1s;
		
		td
		{
			// @include table-padding();
			
			a
			{
				display: block;
			}
			a:not(.external)
			{
			    color: $body-font-color;
			}
			
			input[type="number"]
			{
				height: 2.5rem !important;
			}
		}
		td.tracking_link a:not(.button)
		{
			// height: 1.5rem;
		    // box-sizing: content-box;
		    // padding-left: 0 !important;
		    // padding-right: 0 !important;
		}
	}
}
table.row-links
{
	tr
	{
		td
		{
			padding: 0;
			
			a
			{
				height: rem-calc(24);
				box-sizing: content-box;
				padding: $table-padding;
			}
		}
		td:hover a
		{
			color: $body-font-color;
		}
	}
}
table.data
{
	th:not(:last-of-type)
	{
		border-right: 1px solid #ededed;
	}
	th:hover
	{
		background: #f0f0f0;
	}
	
	td:not(:last-of-type)
	{
		border-right: 1px solid #ededed;
		// border-right: 1px solid black;
	}
	tr:hover td:not(:last-of-type)
	{
		border-right: 1px solid darken(#ededed, 4%);
	}
}

// DataTables's sorting icons suck, let's make some proper ones.
table.dataTable thead .sorting::before
{
	float: right;
	clear: both;
	position: relative;
	top: rem-calc(6);
	@include css-triangle(5px, lighten($body-font-color, 40%), up);
}
table.dataTable thead .sorting::after
{
	float: right;
	clear: both;
	position: relative;
	top: rem-calc(8);
	@include css-triangle(5px, lighten($body-font-color, 40%), down);
}
table.dataTable thead .sorting_asc::after
{
	float: right;
	position: relative;
	top: rem-calc(6);
	@include css-triangle(5px, $dropdownmenu-arrow-color, up);
}
table.dataTable thead .sorting_desc::after
{
	float: right;
	position: relative;
	top: rem-calc(13);
	@include css-triangle(5px, $dropdownmenu-arrow-color, down);
}
table.dataTable thead .sorting_asc_disabled::after
{
	float: right;
	position: relative;
	top: rem-calc(6);
	@include css-triangle(5px, lighten($body-font-color, 40%), up);
}
table.dataTable thead .sorting_desc_disabled::after
{
	float: right;
	position: relative;
	top: rem-calc(6);
	@include css-triangle(5px, lighten($body-font-color, 40%), down);
}

// Data table filter button count indicators.
div.column.filters button
{
	line-height: 1.4rem;
}
button span.badge
{
	position: relative;
	top: -1px;
	margin-left: 0.2rem;
	line-height: 0.9rem;
	font-family: 'Roboto Medium';
}
button:not(.hollow) span.badge
{
	color: $primary-color;
	background: white;
}

// Orders table status colours.
table#table_orders
{
	td.pending a
	{
		// color: #ff713d;
	}
	td.awaiting-payment a
	{
		// color: #ff3d3d;
	}
	td.paid a
	{
		// color: #2fc7ba;
	}
	td.shipped a
	{
		color: $success-color;
	}
	td.cancelled a
	{
		color: $alert-color;
	}
}

// Table tracking link separators.
td.tracking_link:not(:first-of-type)
{
	border-left: 1px solid #ededed;
}
tr:hover td.tracking_link:not(:first-of-type)
{
	border-left: 1px solid darken(#ededed, 4%);
}


/*
 * Stock indicators.
 */
span.stock
{
	// Account for the icon's added height.
	i:not(.material-icons)
	{
		position: relative;
		top: -5px;
	}
}
span.stock_ok
{
	color: $primary-color;
}
span.stock_low
{
	// color: $warning-color;
	color: #7f7f7f;
}
span.stock_out
{
	color: $alert-color;
}

tr.stock_out
{
	background: $alert-color !important;
	color: white;
}

#table_items
{
	// margin-top: 1rem;
	
	@include breakpoint(large)
	{
		margin-top: 0;
	}
}