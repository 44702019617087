.sweet-overlay
{
	background: rgba(10,10,10,.6);
}

.sweet-alert
{
	$header-height: rem-calc(40);
	$footer-height: rem-calc(66);
	$text-color: #212121;
	$padding: rem-calc(24);
	$error-color: #d9453c;

	font-family: 'Roboto';
	padding: $padding;
	padding-top: $header-height + $padding;
	padding-bottom: rem-calc(13);
	text-align: right; // Align buttons
	border-radius: 0;
	box-shadow: 0 0 rem-calc(14) rgba(black, 0.24),0 rem-calc(14) rem-calc(28) rgba(black, 0.48);
	
	h2
	{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: auto;
		font-weight: 400;
		color: $text-color;
		margin: rem-calc(20) 0;
		font-size: 1.2em;
		line-height: 1.25;
		text-align: left;
		padding: 0 $padding;
	}
	
	p
	{
		display: block;
		text-align: center;
		color: $text-color;
		font-weight: 400;
		font-size: rem-calc(14);
		margin: rem-calc(20) 0;
	}
	
	button
	{
		// border-radius: rem-calc(2);
		// box-shadow: none !important;
		// background: none !important;
		position: relative;
		margin-top: 0;
		padding: 0.75rem 1.5rem;
		border-radius: 0;
		box-shadow: none !important;
		font-family: 'Roboto Medium';
		font-size: rem-calc(14);
		text-transform: uppercase;
		transition: background, 0.3s;
	}
	button.cancel
	{
		background: #808080;
	}
	button.cancel:hover
	{
		background: darken(#808080, 5%);
	}
	button.confirm:hover
	{
		background: darken($primary-color, 5%) !important;
		// background: red !important;
	}
	
	.sa-icon:not(.sa-custom)
	{
		transform: scale(0.8);
		margin-bottom: rem-calc(-10);
		margin-top: rem-calc(-10);
	}
	
	input
	{
		border: none;
		border-radius: 0;
		border-bottom: rem-calc(1) solid #c9c9c9;
		color: #212121;
		margin-bottom: rem-calc(8);
		padding: rem-calc(1);
		padding-bottom: rem-calc(8);
		height: auto;
		box-shadow: none;
		font-size: rem-calc(13);
		margin: rem-calc(10) 0;
		
		&:focus
		{
			border: none;
			border-bottom: rem-calc(1) solid #3c80f6;
			box-shadow: inset 0 rem-calc(-1) 0 #3c80f6;
		}
	}
	
	fieldset
	{
		padding: 0;
		
		.sa-input-error
		{
			display: none;
		}
	}
	
	.sa-error-container
	{
		display: none;
		background: none;
		height: auto;
		padding: 0 $padding;
		margin: 0 rem-calc(-20);
		text-align: left;
		
		&.show
		{
			padding: 0 $padding;
			display: block;
			
			~ fieldset input
			{
				background: red;
				border-bottom: rem-calc(1) solid $error-color;
				box-shadow: inset 0 rem-calc(-1) 0 $error-color;
			}
		}
		
		.icon
		{
			display: none;
		}
		p
		{
			color: $error-color;
			margin-top: 0;
		}
	}
}


// Animations:

@mixin keyframes($animation-name)
{
	@-webkit-keyframes #{$animation-name}
	{
		@content;
	}
	@keyframes #{$animation-name}
	{
		@content;
	}
}

@include keyframes(animateErrorIcon)
{
	0%
	{
		transform: rotateX(100deg), scale(0.5); -webkit-transform: rotateX(100deg), scale(0.5); opacity: 0;
	}
	100%
	{
		transform: rotateX(0deg),   scale(0.5); -webkit-transform: rotateX(0deg),   scale(0.5); opacity: 1;
	}
}