/*
-------------------------------------------------------------------------------------
//	FOOTER
-------------------------------------------------------------------------------------
*/

$footer-height: 9rem;
$footer-height-medium: 6.5rem;
$footer-height-large: 4rem;
$footer-margin-top: 1rem;
$footer-margin-top-medium: 2rem;
$footer-margin-top-large: 3rem;

html
{
	position: relative !important;
	min-height: 100% !important;
}

body, html
{
	height: auto !important;
}

body.footer
{
	// Account for footer height on pages taller than window.
	margin-bottom: $footer-height + $footer-margin-top;
	display: block !important;
	
	@include breakpoint(medium)
	{
		margin-bottom: $footer-height-medium + $footer-margin-top-medium;
	}
	@include breakpoint(large)
	{
		margin-bottom: $footer-height-large + $footer-margin-top-large;
	}
}


// -------------- GRID --------------- //

footer
{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: $footer-height;
	padding-top: rem-calc(13);
	background: white;
	
	@include breakpoint(small only)
	{
		// padding-bottom: 9rem;
	}
	@include breakpoint(medium)
	{
		height: $footer-height-medium;
	}
	@include breakpoint(large)
	{
		height: $footer-height-large;
	}
	
	p
	{
		margin-bottom: 0;
	}
	
	.footer-left
	{
		line-height: 1rem;
		padding-top: 0.5rem;
		
		p
		{
			display: inline-block;
			font-size: rem-calc(12);
			color: lighten($body-font-color, 15%);
		}
	}
	
	.footer-right
	{
		@include breakpoint(small only)
		{
			margin-bottom: 0.5rem;
		}
		
		p
		{
			font-family: 'Open Sans Bold Italic';
			font-size: rem-calc(11);
			color: lighten($body-font-color, 35%);
			
			img
			{
				margin-right: 0.5rem;
			}
		}
	}
}


// -------------- HYBRID --------------- //

// footer
// {
// 	padding-top: 2rem;
	
// 	.container
// 	{
// 		position: absolute;
// 		bottom: 0;
// 		width: 100%;
// 		height: $footer-height;
// 		background-image: url('../img/footer-left.svg');
// 		background-position: top right;
		
// 		@include breakpoint(medium)
// 		{
// 			height: $footer-height-medium;
// 		}
// 		@include breakpoint(large)
// 		{
// 			height: $footer-height-large;
// 		}
		
// 		p
// 		{
// 			margin-bottom: 0;
// 		}
		
// 		.row.expanded
// 		{
// 			height: 0;
// 			margin-top: 4rem;
			
// 			.footer-left
// 			{
// 				height: $footer-height;
// 				margin-top: -4rem;
				
// 				@include breakpoint(medium)
// 				{
// 					height: $footer-height-medium;
// 				}
// 				@include breakpoint(large)
// 				{
// 					height: $footer-height-large;
// 				}
// 			}
// 			.footer-right
// 			{
// 				height: $footer-height;
// 				margin-top: -4rem;
// 				padding-top: 1.5rem; // Account for the cutout in the svg background.
// 				padding-left: 6rem;
// 				background-image: url('../img/footer-right.svg');
// 				background-repeat: repeat-x;
				
// 				@include breakpoint(medium)
// 				{
// 					height: $footer-height-medium;
// 				}
// 				@include breakpoint(large)
// 				{
// 					height: $footer-height-large;
// 				}
				
// 				p.logo_footer
// 				{
// 					font-family: 'Open Sans Bold Italic';
// 					font-size: rem-calc(11);
// 					color: lighten($body-font-color, 35%);
					
// 					img
// 					{
// 						margin-right: 0.5rem;
// 					}
// 				}
// 			}
// 		}
		
// 		.row.content
// 		{
// 			margin-top: 2.8125rem;
// 			height: 1rem;
			
// 			.text
// 			{
// 				line-height: 1rem;
// 			}
			
// 			p
// 			{
// 				display: inline-block;
// 				font-size: rem-calc(12);
// 				color: lighten($body-font-color, 15%);
// 			}
// 		}
// 	}
// }


// -------------- NO GRID --------------- //

// footer
// {
// 	padding-top: 2rem;
	
// 	.container
// 	{
// 		position: absolute;
// 		bottom: 0;
// 		width: 100%;
// 		height: 5.5rem;
// 		background-image: url('../img/footer-left.svg');
// 		background-position: top right;
// 	}
	
// 	.content
// 	{
// 		margin-top: 2.8125rem;
// 		height: 1rem;
		
// 		.text
// 		{
// 			line-height: 1rem;
// 		}
		
// 		p
// 		{
// 			display: inline-block;
// 			font-size: rem-calc(12);
// 			color: lighten($body-font-color, 15%);
// 		}
// 	}
	
// 	.row.expanded
// 	{
// 		height: 0;
// 	}
	
// 	p
// 	{
// 		margin-bottom: 0;
// 	}
	
// 	.footer-left
// 	{
// 		margin-top: -4rem;
// 		height: 5.5rem;
// 	}
// 	.footer-right
// 	{
// 		height: 5.5rem;
// 		margin-top: -4rem;
// 		padding-top: 1.5rem; // Account for the cutout in the svg background.
// 		padding-left: 6rem;
// 		background-image: url('../img/footer-right.svg');
		
// 		p.logo_footer
// 		{
// 			font-family: 'Open Sans Bold Italic';
// 			font-size: rem-calc(11);
// 			color: lighten($body-font-color, 35%);
			
// 			img
// 			{
// 				margin-right: 0.5rem;
// 			}
// 		}
// 	}
// }