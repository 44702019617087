// Product images.
.container-image
{
	// float: right;
	position: relative;
	max-height: rem-calc(400);
	overflow: hidden;
	transition: background, 0.2s;
	
	img
	{
		// float: right;
	}
}
.container-image.admin
{
	border: 1px solid #cdcdcd;
	
	.overlay
	{
		position: absolute;
		width: 100%;
		height: 100%;
		transition: opacity, 0.2s;
		background: rgba(255,255,255,0.9);
		text-align: center;
		opacity: 0;
		
		h5
		{
			display: block;
			position: absolute;
			top: 50%;
			width: 100%;
			height: 2rem;
			margin-top: -1rem;
			margin-bottom: 0;
			
			font-size: rem-calc(18);
			// font-family: 'Open Sans Semibold';
			
			i
			{
				position: relative;
				left: rem-calc(2);
				top: rem-calc(4);
			}
		}
	}
}
.container-image.admin:hover .overlay
{
	opacity: 1;
}

// Product image index.
// div.thumbnail.primary
// {
// 	border: 1px solid $primary-color;
// }
// div.thumbnail.primary::before
// {
// 	@include material();
	
// 	position: absolute;
// 	// margin-left: rem-calc(5);
// 	// margin-top: rem-calc(5);
// 	padding: rem-calc(5);
// 	font-size: rem-calc(21);
// 	color: $primary-color;
// 	// border: 1px solid rgba(255,255,255,0.25);
// 	background: #f2f2f2;
// 	// background: white;
	
// 	content: "star";
// }
div.thumbnail.primary
{
	padding-bottom: 1rem !important;
}
div.thumbnail.primary::after
{
	position: relative;
	top: rem-calc(7);
	
	@include material();
	
	// content: "Primary image";
	content: "star";
	color: $primary-color;
}
div.thumbnail.primary.selected::after
{
	color: white;
}

.grid img
{
	cursor: pointer;
}