#breadcrumbs
{
	padding-top: 1rem;
	padding-bottom: 1rem;
	
	.trail
	{
		margin: 0;
		overflow: hidden;
		list-style: none;
		
		li
		{
			float: left;
			// background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%);
			border: 1px solid #d9d9d9;
			
			a
			{
				text-decoration: none;
				display: block;
				position: relative;
				float: left;
				padding: 10px 15px 10px 30px;
				background: #f7f7f7;
				transition: background-color, 0.2s;
			}
			a:after
			{
				content: "";
				display: block;
				position: absolute;
				left: 100%;
				top: 50%;
				width: 0;
				height: 0;
				margin-top: -24px;
				border-top: 24px solid transparent;
				border-bottom: 24px solid transparent;
				border-left: 15px solid #f7f7f7;
				transition: background-color, 0.2s;
				z-index: 2;
			}
			a:before
			{
				content: "";
				display: block;
				position: absolute;
				left: 100%;
				top: 50%;
				width: 0;
				height: 0;
				margin-top: -24px;
				margin-left: 1px;
				border-top: 24px solid transparent;      
				border-bottom: 24px solid transparent;
				border-left: 15px solid #d9d9d9;
				z-index: 1;
			}
			
			a:hover
			{
				background: darken(#f7f7f7, 3%);
			}
			a:hover:after
			{
				border-left-color: darken(#f7f7f7, 3%);
			}
		}
		
		li:last-child a
		{
			pointer-events: none;
			cursor: default;
			color: #737373;
		}
	}
}